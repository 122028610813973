import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';

const Status = () => {

    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState("");
    const [status_name, setStatusName]   = useState("");
    const [status_code, setStatusCode] = useState("");
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(statuses)
    const [tableFilter, setTableFilter] = useState([])
    const [loading, setLoading] = useState(true);
  
  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
  
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
  
      // get all Status -- Admin part
      const getAllStatus = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/status')
        // , {
        //     headers: {
        //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        //     },
        // });
        console.log(response.data);
        setStatuses(response.data);
    }
  
    const getStatusById = async (id) => {
      const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/status/' + id)
      console.log(data)
      setStatus(data.data)
    }
  
  const AddStatus = async (id) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {
  
        let formField = new FormData()
        formField.append('status_code', status_code)
        formField.append('status_name', status_name)
  
        await axios({
            method: 'post',
            url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/status',
            // headers: {
            //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            //     },
            data: formField
        }).then((response) => {
            console.log(response.data)
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            getAllStatus();
            handleClose();
            setStatusCode('');
            setStatusName('');
        })
    // }
    // setValidated(true);
  }

  const updateStatus = async (id) => {

    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {


  let formField = new FormData()
  formField.append('status_code', status_code)
  formField.append('status_name', status_name)

  await axios({
      method: 'put',
      url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/status/' + id),
  //  headers: {
  //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
  //     },
      data: formField
  }).then((response) => {
      if (response.data.status === 200) {
          toast.success(response.data.details)
      }
      else if (response.data.status === 400) {
          toast.error(response.data.details)
      }
      getAllStatus();
      handleClose();
      setStatusCode('')
      setStatusName('')
  })
}
  
  const deleteStatus = async (id) => {
    const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/status/' + id)
    console.log(response.data)
    if (response.data.status === 200) {
        toast.success(response.data.details)
    }
    else if (response.data.status === 400) {
        toast.error(response.data.details)
    }
     getAllStatus();
    }

    const filterStatus = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = statuses.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...statuses])
        }
    }
  
    useEffect(() => {
        getAllStatus();
      }, [])

    
    // Pagination starts
        const perPage = 10;
        const [currentPage, setCurrentPage] = useState(0);

        const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
        };

        const offset = currentPage * perPage;
        const paginatedStatus = statuses.slice(offset, offset + perPage);
        const paginatedStatusFilter = tableFilter.slice(offset, offset + perPage);


      
    //   pagination ends
      const StatusList = () => {

        return value.length > 0 ? paginatedStatusFilter.map((status, xp) => {
            return (
                <tr key={status.id}>
                <td scope='row'>{xp+1}</td>
                <td>{status.status_code}</td>
                <td>{status.status_name}</td>
                <td>
                    <Link  to="/status" onClick={() => { getStatusById(status.id); handleShowStatus(); }} ><i className="fas fa-eye text-secondary" /></Link>
                    <Link  to="/status" onClick={() => { getStatusById(status.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                    <Link  to="/status" onClick={() => deleteStatus(status.id)} ><i className="fa fa-trash text-danger" /></Link>
                </td>
            </tr>
            )
        }
        )
        :

        paginatedStatus.map((status, xp) => (
          
          <tr key={status.id}>
              <td scope='row'>{xp+1}</td>
              <td>{status.status_code}</td>
              <td>{status.status_name}</td>
              <td>
                  <Link  to="/status" onClick={() => { getStatusById(status.id); handleShowStatus(); }} ><i className="fas fa-eye text-secondary" /></Link>
                  <Link  to="/status" onClick={() => { getStatusById(status.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                  <Link  to="/status" onClick={() => deleteStatus(status.id)} ><i className="fa fa-trash text-danger" /></Link>
              </td>
          </tr>
        )
          )
      }
      
      return (
        

    <div>

        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                        <li className="breadcrumb-item active">Status</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="container-fluid">
                <div className="card">
                    <div className="row">
                        <div className="card-header col-sm-9">
                            <h3 className="card-title">Status</h3>
                        </div>
                        <div className="card-header col-sm-3 text-right">
                            <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Status</Button>
                        </div>
                    </div>
                
                    <div className="card-body">
                            <InputGroup className="mb-3">
                                <Form.Control
                                placeholder="search"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value = {value}
                                onChange={filterStatus}
                                />
                            </InputGroup>
                            <table className="table table-bordered">
                                <thead>
                             <tr>
                               <th>#</th>
                               <th>Status Code</th>
                               <th>Status Name</th>
                               <th>Action</th>
                            </tr>
                                </thead>
                                <tbody>
                                    <StatusList />
                                </tbody>
                                <tfoot>
                                        <tr>
                                            <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(statuses.length / perPage)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageChange}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            />
                                        </tr>
                                </tfoot>
                            </table>
                    </div>
                
                </div>
            </div>
        </section>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Status</Modal.Title>
        </Modal.Header>
                   <Modal.Body>
                       <Form noValidate validated={validated}>
                       <Form.Group className="mb-2">
                               <Form.Label>Status Code</Form.Label>
                               <Form.Control size="sm" type="text" 
                                   name = "status_code"
                                   value = {status_code}
                                   placeholder="Enter Status Code"
                                   onChange={(e) => setStatusCode(e.target.value)}
                                   required
                                   />
                               <Form.Control.Feedback type="invalid">
                                   Status Code is required
                               </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group className="mb-2">
                               <Form.Label>Status Name</Form.Label>
                               <Form.Control size="sm" type="text" 
                                   name = "status_name"
                                   value = {status_name}
                                   placeholder="Enter Status Name"
                                   onChange={(e) => setStatusName(e.target.value)}
                                   required
                                   />
                               <Form.Control.Feedback type="invalid">
                                   Status Name is required
                               </Form.Control.Feedback>
                           </Form.Group>
                                  {/* <Form.Control size="sm" type="hidden"
                                   name = "created_by"
                                   value = {created_by}
                                   onChange={(e) => setCreatedBy(e.target.value)}
                                   />
                           </Form.Group> */}
                       </Form>
                   </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={AddStatus}>
            Submit
        </Button>
        </Modal.Footer>
        </Modal>

        {/* Status Detail */}
    <Modal show={showStatus} onHide={handleCloseStatus}>
        <Modal.Header closeButton>
            <Modal.Title>Status Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table className="table table-bordered">
                            <tbody>
                                         <tr>
                                             <th>ID</th>
                                             <td>{status.id}</td>
                                         </tr>
                                         <tr>
                                             <th>Status Code</th>
                                             <td>{status.status_code}</td>
                                         </tr>
                                         <tr>
                                             <th>Status Name</th>
                                             <td>{status.status_name}</td>
                                         </tr>
                                         <tr>
                                             <th>Created</th>
                                             <td>{status.created_at}</td>
                                         </tr>
                            </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseStatus}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>


        {/* Edit Status */}
        <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Status</Modal.Title>
        </Modal.Header>
                   <Modal.Body>
                       <Form noValidate validated={validated}>
                       <Form.Group className="mb-2">
                               <Form.Label>Status Code</Form.Label>
                               <Form.Control size="sm" type="text" 
                                   name = "status_code"
                                   defaultValue = {status.status_code}
                                   onChange={(e) => setStatusCode(e.target.value)}
                                   required
                                   />
                               <Form.Control.Feedback type="invalid">
                                   Status Code is required
                               </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group className="mb-2">
                               <Form.Label>Status Name</Form.Label>
                               <Form.Control size="sm" type="text" 
                                   name = "status_name"
                                   defaultValue = {status.status_name}
                                   onChange={(e) => setStatusName(e.target.value)}
                                   required
                                   />
                               <Form.Control.Feedback type="invalid">
                                   Status Name is required
                               </Form.Control.Feedback>
                           </Form.Group>
                                  {/* <Form.Control size="sm" type="hidden"
                                   name = "created_by"
                                   value = {created_by}
                                   onChange={(e) => setCreatedBy(e.target.value)}
                                   />
                           </Form.Group> */}
                       </Form>
                   </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={() => {updateStatus(status.id); handleCloseEdit();}}>
            Update
        </Button>
        </Modal.Footer>
        </Modal>
    </div>

      )

}

export default Status