import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { loginRequest } from '../../utils/API';
import { login } from '../../features/userSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

//   const handleLogin = async () => {

//   };

  const loginUser = async (event) => {

    event.preventDefault();
    const form = event.currentTarget;

    if (isLoading) return;
    

    try {
      setIsLoading(true);
      // Perform your login logic here

      if (form.checkValidity() === false || username === '' || password === '') {
        setValidated(true);
        return;
      }
      const response = await loginRequest(username, password);
  
      if (response.status === 200) {
        toast.success(response.details);
      } else if (response.status === 400) {
        toast.error(response.details);
      }

      const { token, user } = response;
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('user_id', JSON.stringify(user.id));
      localStorage.setItem('user', JSON.stringify(user));

      // Dispatch login action if needed
      dispatch(login(user));

      navigate('/home');

      // Simulate a delay (replace with your actual login request)
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Login successful, perform further actions if needed


    } catch (error) {
      // Handle login error
      console.error('Login failed', error);

    } finally {
      // Reset loading state after login attempt
      setIsLoading(false);
    }

  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <b className="h3">SMART-MAUZO</b>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to smart-mauzo</p>

            <Form noValidate validated={validated} onSubmit={loginUser}>
              <Form.Group className="mb-2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Email is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Password is required.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="row">
                <Button
                  type="submit"
                  variant="primary"
                  className="btn btn-block"
                  disabled={isLoading}
                >
                  {isLoading ? 'Signing in...' : 'Sign In'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;