import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';


const DefaultLayout = () => {

  const user = JSON.parse(localStorage.getItem('user'))
  console.log(user)

  return (
    <div>
      {user !== null ? (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <div className='content-wrapper'>
                <Outlet />
            </div>
            <Footer />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  )
}

export default DefaultLayout