import React, { useState, useEffect } from 'react'
import { Modal, Table, Form, Button, InputGroup } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { searchCompany, searchCompanyBranch, searchBranchProduct, searchBProduct } from '../../utils/API'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils';


function Deliveries() {

    const [stocks, setStocks] = useState([]);
    const [company, setCompany] = useState("")
    const [branch, setBranch] = useState("")
    const [product, setProduct] =useState("")
    const [quantity, setQuantity] =useState("")
    const [buying_price, setBuyingPrice] =useState("")
    const [retail_price, setRetailPrice] = useState("")
    const [wholesale_price, setWholesalePrice] =useState("")
    const [stock, setStock] = useState("")
    const [value, setValue] = useState("")
    const [companyStocks, setCompanyStocks] = useState([]);
    const [branchStocks, setBranchStocks] = useState([]);
    const [tableFilter, setTableFilter] = useState([])
    const [dataSource, setDataSource] = useState(stocks)
    const [companydataSource, setCompanyDataSource] = useState(companyStocks)
    const [branchdataSource, setBranchDataSource] = useState(branchStocks)
    const [validated, setValidated] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showStock, setShowStock] = useState(false);
    const handleCloseStock = () => setShowStock(false);
    const handleShowStock = () => setShowStock(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    // Get all data from stocks -- Admin
    const getStocks = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/stocks', {
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
        });
        console.log(response.data);
        setStocks(response.data);
    }

    // Get all stocks on a company of a loggedIn user
    const getCompanyStocks = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-deliveries', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setCompanyStocks(response.data.data);
    }

    // Get stocks on a branch of a loggedIn user
    const getBranchStocks = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-deliveries', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setBranchStocks(response.data.data);
    }

    // Get stocks by ID
    const getStockById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/stock/' + id, {
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
        })
        console.log(data)
        setStock(data.data)
    }

    // Filter all stocks by search -- Admin
    const filterStocks = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = stocks.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...stocks])
        }
    }


    // Filter stocks on a company of a loggedIn user
    const filterCompanyStocks = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = companyStocks.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setCompanyDataSource([...companyStocks])
        }
    }

    // Filter stocks on a branch of a loggedIn user
    const filterBranchStocks = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = branchStocks.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setBranchDataSource([...branchStocks])
        }
    }

    const AddStock = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (company !== '' && branch !== '' && product !== '' &&
        quantity !== '' && buying_price !== '' && retail_price !== ''
        && wholesale_price !== '') {

            let formField = new FormData()
            formField.append('company', company)
            formField.append('branch', branch)
            formField.append('product', product)
            formField.append('quantity', quantity)
            formField.append('buying_price', buying_price)
            formField.append('retail_price', retail_price)
            formField.append('wholesale_price', wholesale_price)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/stocks',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getStocks();
                handleClose();
                setCompany('')
                setBranch('')
                setProduct('')
                setQuantity('')
                setBuyingPrice('')
                setRetailPrice('')
                setWholesalePrice('')
            })
        }
        setValidated(true);

    }

    const AddCompanyStock = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (branch !== '' && product !== '' && quantity !== '' && buying_price !== '' && retail_price !== ''
        && wholesale_price !== '') {

            let formField = new FormData()
            formField.append('branch', branch)
            formField.append('product', product)
            formField.append('quantity', quantity)
            formField.append('buying_price', buying_price)
            formField.append('retail_price', retail_price)
            formField.append('wholesale_price', wholesale_price)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/company-deliveries',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getCompanyStocks();
                handleClose();
                setBranch('')
                setProduct('')
                setQuantity('')
                setBuyingPrice('')
                setRetailPrice('')
                setWholesalePrice('')
            })
        }
        setValidated(true);

    }

    const AddBranchStock = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (product !== '' && quantity !== '' && buying_price !== '' && retail_price !== ''
        && wholesale_price !== '') {

            let formField = new FormData()
            formField.append('product', product)
            formField.append('quantity', quantity)
            formField.append('buying_price', buying_price)
            formField.append('retail_price', retail_price)
            formField.append('wholesale_price', wholesale_price)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-deliveries',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getBranchStocks();
                handleClose();
                setProduct('')
                setQuantity('')
                setBuyingPrice('')
                setRetailPrice('')
                setWholesalePrice('')
            })
        }
        setValidated(true);

    }

    const editStock = async (id) => {
        
        let formField = new FormData()
        formField.append('company', company)
        formField.append('branch', branch)
        formField.append('product', product)
        formField.append('quantity', quantity)
        formField.append('buying_price', buying_price)
        formField.append('retail_price', retail_price)
        formField.append('wholesale_price', wholesale_price)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/stock/' + id),
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
            data: formField
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            setCompany('')
            setBranch('')
            setProduct('')
            setQuantity('')
            setBuyingPrice('')
            setRetailPrice('')
            setWholesalePrice('')
            getStocks();
        })
    }

    const deleteStock = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/stock/' + id, {
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
        })
        console.log(response)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getStocks();
    }

    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectBranch = (e) => {
        setBranch(e.value)
    }

    const loadBranch = (inputValue, callback) => {
        if (company !== null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const selectProduct = (e) => {
        setProduct(e.value)
    }

    const loadProduct = (inputValue, callback) => {
        if (branch !== null) {
          searchBranchProduct(branch, inputValue).then((response) => {
            console.log('Products')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const loadBranchProduct = (inputValue, callback) => {

          searchBProduct(product, inputValue).then((response) => {
            console.log(response.data)
            const re = response.data.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedDeliveries = stocks.slice(offset, offset + perPage);
    const paginatedCompanyDeliveries = companyStocks.slice(offset, offset + perPage);
    const paginatedBranchDeliveries = branchStocks.slice(offset, offset + perPage);
    const paginatedDeliveriesFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getStocks();
        getCompanyStocks();
        getBranchStocks();
    }, [])

    const ListStocks = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        
        if (user.is_superuser) {

            return value.length > 0 ? paginatedDeliveriesFilter.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedDeliveries.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )

        }
        
        else if (user.company !== null && user.is_superuser) {

            return value.length > 0 ? paginatedDeliveriesFilter.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedCompanyDeliveries.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )

        }
        else {

            return value.length > 0 ? paginatedDeliveriesFilter.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedBranchDeliveries.map((stock, st) => {
                return (
                <tr key={stock.id}>
                    <td scope='row'>{st+1}</td>
                    <td>{stock.product.name}</td>
                    <td>{formatAmountWithCommas(stock.quantity)}</td>
                    <td>{stock.branch.name}</td>
                    <td>{formatDateTimeString(stock.created_at)}</td>
                    <td>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowStock(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => { getStockById(stock.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-deliveries" onClick={() => deleteStock(stock.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )

        }
    }

    if (user.is_superuser) {
        return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Stock-Deliveries</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Stock Deliveries</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add stock</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                                <Form.Control
                                                placeholder="search"
                                                aria-label="search"
                                                aria-describedby="basic-addon1"
                                                value = {value}
                                                onChange={filterStocks}
                                                />
                                        </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Branch</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                    <ListStocks />
                                            </tbody>
                                            <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(stocks.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                            </tfoot>
                                        </table>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                        {/* Add stock */}
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Item to Stock</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form noValidate validated={validated}>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6"> 
                                        <Form.Label>Company</Form.Label>
                                        <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Branch</Form.Label>
                                        <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} required />
                                        <Form.Control.Feedback type="invalid">
                                            loadBranches is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product</Form.Label>
                                        <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                        <Form.Control.Feedback type="invalid">
                                            Product is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                                name="quantity"
                                                value={quantity}
                                                onChange={(e) => setQuantity(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Quantity is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Buying Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                                name="buying_price"
                                                value={buying_price}
                                                onChange={(e) => setBuyingPrice(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Buying price is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Retail Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                                name="retail_price"
                                                value={retail_price}
                                                onChange={(e) => setRetailPrice(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Retail price is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Wholesale Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter wholesale price" 
                                                name="wholesale_price"
                                                value={wholesale_price}
                                                onChange={(e) => setWholesalePrice(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            wholesale price is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={AddStock}>
                                Submit
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* stock Detail */}
                        <Modal show={showStock} onHide={handleCloseStock}>
                            <Modal.Header closeButton>
                                <Modal.Title>Item in Stock Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td>{stock.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company</th>
                                                    <td>{stock.company ? stock.company.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Branch</th>
                                                    <td>{stock.branch ? stock.branch.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product</th>
                                                    <td>{stock.product ? stock.product.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <td>{stock.quantity}</td>
                                                </tr>
                                                <tr>
                                                    <th>Buying Price</th>
                                                    <td>{stock.buying_price}</td>
                                                </tr>
                                                <tr>
                                                    <th>Retail Price</th>
                                                    <td>{stock.retail_price}</td>
                                                </tr>
                                                <tr>
                                                    <th>Wholesale Price</th>
                                                    <td>{stock.wholesale_price}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(stock.created_at)}</td>
                                                </tr>
                                            </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseStock}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>


                        {/* Edit Stock */}
                        <Modal show={showEdit} onHide={handleCloseEdit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Item in Stock</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6"> 
                                        <Form.Label>Company</Form.Label>
                                        <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Branch</Form.Label>
                                        <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} />
                                    </Form.Group>
                                    </div>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product</Form.Label>
                                        <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                                name="quantity"
                                                defaultValue={stock.quantity}
                                                onChange={(e) => setQuantity(e.target.value)}
                                                />
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Buying Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                                name="buying_price"
                                                defaultValue={stock.buying_price}
                                                onChange={(e) => setBuyingPrice(e.target.value)}
                                                />
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Retail Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                                name="retail_price"
                                                defaultValue={stock.retail_price}
                                                onChange={(e) => setRetailPrice(e.target.value)}
                                                />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Wholesale Price</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter wholesale_price" 
                                                name="wholesale_price"
                                                defaultValue={stock.wholesale_price}
                                                onChange={(e) => setWholesalePrice(e.target.value)}
                                                />
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={() => {editStock(stock.id); handleCloseEdit();}}>
                                Update
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
        )
    }
    else if (user.company !== null && user.is_superuser) {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Stock-Deliveries</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Stock Deliveries</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add stock</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                            <Form.Control
                                            placeholder="search"
                                            aria-label="search"
                                            aria-describedby="basic-addon1"
                                            value = {value}
                                            onChange={filterCompanyStocks}
                                            />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Branch</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListStocks />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(companyStocks.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
                    {/* Add stock */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Item to Stock</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        loadBranches is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Product is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Buying Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                            name="buying_price"
                                            value={buying_price}
                                            onChange={(e) => setBuyingPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Buying price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Retail Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                            name="retail_price"
                                            value={retail_price}
                                            onChange={(e) => setRetailPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Retail price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Wholesale Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter wholesale price" 
                                            name="wholesale_price"
                                            value={wholesale_price}
                                            onChange={(e) => setWholesalePrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        wholesale price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddCompanyStock}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* stock Detail */}
                    <Modal show={showStock} onHide={handleCloseStock}>
                        <Modal.Header closeButton>
                            <Modal.Title>Item in Stock Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{stock.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{stock.company ? stock.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Branch</th>
                                                <td>{stock.branch ? stock.branch.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{stock.product ? stock.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{stock.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Buying Price</th>
                                                <td>{stock.buying_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Retail Price</th>
                                                <td>{stock.retail_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Wholesale Price</th>
                                                <td>{stock.wholesale_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{formatDateTimeString(stock.created_at)}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseStock}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Stock */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Item in Stock</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6"> 
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} />
                                </Form.Group>
                                </div>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            defaultValue={stock.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Buying Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                            name="buying_price"
                                            defaultValue={stock.buying_price}
                                            onChange={(e) => setBuyingPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Retail Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                            name="retail_price"
                                            defaultValue={stock.retail_price}
                                            onChange={(e) => setRetailPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Wholesale Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter wholesale_price" 
                                            name="wholesale_price"
                                            defaultValue={stock.wholesale_price}
                                            onChange={(e) => setWholesalePrice(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editStock(stock.id); handleCloseEdit();}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
    else {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Stock-Deliveries</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Stock Deliveries</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add stock</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                            <Form.Control
                                            placeholder="search"
                                            aria-label="search"
                                            aria-describedby="basic-addon1"
                                            value = {value}
                                            onChange={filterBranchStocks}
                                            />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Branch</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListStocks />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(branchStocks.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
                    {/* Add stock */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Item to Stock</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadBranchProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Product is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Buying Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                            name="buying_price"
                                            value={buying_price}
                                            onChange={(e) => setBuyingPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Buying price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Retail Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                            name="retail_price"
                                            value={retail_price}
                                            onChange={(e) => setRetailPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Retail price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Wholesale Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter wholesale price" 
                                            name="wholesale_price"
                                            value={wholesale_price}
                                            onChange={(e) => setWholesalePrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        wholesale price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddBranchStock}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* stock Detail */}
                    <Modal show={showStock} onHide={handleCloseStock}>
                        <Modal.Header closeButton>
                            <Modal.Title>Item in Stock Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{stock.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{stock.company ? stock.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Branch</th>
                                                <td>{stock.branch ? stock.branch.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{stock.product ? stock.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{stock.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Buying Price</th>
                                                <td>{stock.buying_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Retail Price</th>
                                                <td>{stock.retail_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Wholesale Price</th>
                                                <td>{stock.wholesale_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{formatDateTimeString(stock.created_at)}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseStock}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Stock */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Item in Stock</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadBranchProduct} defaultInputValue={stock.product} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            defaultValue={stock.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Buying Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter buying price"
                                            name="buying_price"
                                            defaultValue={stock.buying_price}
                                            onChange={(e) => setBuyingPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Retail Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter retail price"
                                            name="retail_price"
                                            defaultValue={stock.retail_price}
                                            onChange={(e) => setRetailPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Wholesale Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter wholesale_price" 
                                            name="wholesale_price"
                                            defaultValue={stock.wholesale_price}
                                            onChange={(e) => setWholesalePrice(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editStock(stock.id); handleCloseEdit();}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}

export default Deliveries