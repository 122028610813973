import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Modal, Table, Form, Button } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { searchCompanyBranch, searchCompany, searchBProduct, searchBranchProduct, searchCBranches} from '../../utils/API'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils';

function Transfer() {

    const [transfers, setTransfers] = useState([]);
    const [company, setCompany] =useState("")
    const [transfer_from, setTransferFrom] =useState("")
    const [transfer_to, setTransferTo] =useState("")
    const [product, setProduct] =useState("")
    const [quantity, setQuantity] =useState("")
    const [unit_cost, setUnitCost] =useState("")
    const [transfer, setTransfer] = useState("")
    const [companyTransfers, setCompanyTransfers] = useState([]);
    const [branchTransfers, setBranchTransfers] = useState([]);
    const [validated, setValidated] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTransfer, setShowTransfer] = useState(false);
    const handleCloseTransfer = () => setShowTransfer(false);
    const handleShowTransfer = () => setShowTransfer(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    // Get all transfers records -- Admin
    const getTransfers = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/transferred-products');
        console.log(response.data);
        setTransfers(response.data);
    }

    // Get transfers on company of a loggedIn user
    const getCompanyTransfers = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-transfers', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setCompanyTransfers(response.data.data);
    }

    // Get transfers on branch of a loggedIn user
    const getBranchTransfers = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-transfers', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setBranchTransfers(response.data.data);
    }

    const getTransferById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/transferred-product/' + id, {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
    })
        console.log(data)
        setTransfer(data)
    }

    const AddTransfer = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (company !== '' && transfer_from !== '' && transfer_to !== '' &&
        product !=='' && quantity !== '' && unit_cost !== '') {

            let formField = new FormData()
            formField.append('company', company)
            formField.append('transfer_from', transfer_from)
            formField.append('transfer_to', transfer_to)
            formField.append('product', product)
            formField.append('quantity', quantity)
            formField.append('unit_cost', unit_cost)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/transferred-products',
                headers: {
                      Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                    },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                else if (response.data.status === 401) {
                    toast.error(response.data.details)
                }
                getTransfers();
                handleClose();
                setCompany('')
                setTransferFrom('')
                setTransferTo('')
                setProduct('')
                setQuantity('')
                setUnitCost('')
            })
        }
        setValidated(true);
    }

    const AddBranchTransfer = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (transfer_to !== '' && product !=='' && quantity !== '' && unit_cost !== '') {

            let formField = new FormData()
            formField.append('transfer_to', transfer_to)
            formField.append('product', product)
            formField.append('quantity', quantity)
            formField.append('unit_cost', unit_cost)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-transfers',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token'))
                  },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                else if (response.data.status === 401) {
                    toast.error(response.data.details)
                }
                getBranchTransfers();
                handleClose();
                setTransferTo('')
                setProduct('')
                setQuantity('')
                setUnitCost('')
            })
        }
        setValidated(true);
    }

    const editTransfer = async (id) => {
        
        let formField = new FormData()
        formField.append('company', company)
        formField.append('transfer_from', transfer_from)
        formField.append('transfer_to', transfer_to)
        formField.append('product', product)
        formField.append('quantity', quantity)
        formField.append('unit_cost', unit_cost)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/transferred-product/' + id),
            headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            data: formField
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            handleCloseEdit();
            setCompany('')
            setTransferFrom('')
            setTransferTo('')
            setProduct('')
            setQuantity('')
            setUnitCost('')
            getTransfers();
        })
    }

    const deleteTransfer = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/transferred-product/' + id, {
                headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
        });
        console.log(response)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getTransfers();
    }

    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectTransferFrom = (e) => {
        setTransferFrom(e.value)
    }

    const selectTransferTo = (e) => {
        setTransferTo(e.value)
    }


    const loadBranch = (inputValue, callback) => {
        if (company !== null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const selectProduct = (e) => {
        setProduct(e.value)
    }

    const loadProduct = (inputValue, callback) => {
        if (transfer_from !== null) {
          searchBranchProduct(transfer_from, inputValue).then((response) => {
            console.log('Products')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const loadCBranch = (inputValue, callback) => {

        searchCBranches(transfer_to, inputValue).then((response) => {
          console.log(response)
          const re = response.map((data) => {
            console.log(data)
            return {
              value: data.id,
              label: data.name,
            }
          })
          console.log(re)
          callback(re)
        })
  }

    const loadBranchProduct = (inputValue, callback) => {

        searchBProduct(product, inputValue).then((response) => {
          console.log(response.data)
          const re = response.data.map((data) => {
            console.log(data)
            return {
              value: data.id,
              label: data.name,
            }
          })
          console.log(re)
          callback(re)
        })
  }

  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * perPage;
  const paginatedTransfers = transfers.slice(offset, offset + perPage);
  const paginatedCompanyTransfers = companyTransfers.slice(offset, offset + perPage);
  const paginatedBranchTransfers = branchTransfers.slice(offset, offset + perPage);
//   const paginatedTransferFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getTransfers();
        getCompanyTransfers();
        getBranchTransfers();
    }, [])

    const ListTransfers = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        
        if (user.is_superuser) {

            return paginatedTransfers.map((transfer, tr) => {
                return (
                <tr key={transfer.id}>
                    <td scope='row'>{tr+1}</td>
                    <td>{transfer.transfer_from.name}</td>
                    <td>{transfer.transfer_to.name}</td>
                    <td>{transfer.product.name}</td>
                    <td>{formatAmountWithCommas(transfer.quantity)}</td>
                    <td>{formatDateTimeString(transfer.transfer_date)}</td>
                    <td>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowTransfer(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => deleteTransfer(transfer.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else if (user.company !== null && user.is_superuser) {

            return paginatedCompanyTransfers.map((transfer, tr) => {
                return (
                <tr key={transfer.id}>
                    <td scope='row'>{tr+1}</td>
                    <td>{transfer.transfer_from.name}</td>
                    <td>{transfer.transfer_to.name}</td>
                    <td>{transfer.product.name}</td>
                    <td>{formatAmountWithCommas(transfer.quantity)}</td>
                    <td>{formatDateTimeString(transfer.transfer_date)}</td>
                    <td>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowTransfer(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => deleteTransfer(transfer.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else{

            return paginatedBranchTransfers.map((transfer, tr) => {
                return (
                <tr key={transfer.id}>
                    <td scope='row'>{tr+1}</td>
                    <td>{transfer.transfer_from.name}</td>
                    <td>{transfer.transfer_to.name}</td>
                    <td>{transfer.product.name}</td>
                    <td>{formatAmountWithCommas(transfer.quantity)}</td>
                    <td>{formatDateTimeString(transfer.transfer_date)}</td>
                    <td>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowTransfer(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => { getTransferById(transfer.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/stock-transfer" onClick={() => deleteTransfer(transfer.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
    }

    if (user.is_superuser) {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Transfer-records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Transfer Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make Transfer</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Transfer From</th>
                                                    <th>Transfer To</th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Transfer Date</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListTransfers />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(transfers.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>

                    {/* Make Transfer */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="row">
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>FROM</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferFrom(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferTo(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                    {/* <Form.Control size="sm" type="text" placeholder="Choose product" 
                                            name="product"
                                            value={product}
                                            onChange={(e) => setProduct(e.target.value)}
                                            /> */}
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            value={unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit cost is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddTransfer}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Transfer Detail */}
                    <Modal show={showTransfer} onHide={handleCloseTransfer}>
                        <Modal.Header closeButton>
                            <Modal.Title>Transfer Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{transfer.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{transfer.company ? transfer.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer From</th>
                                                <td>{transfer.transfer_from ? transfer.transfer_from.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer To</th>
                                                <td>{transfer.transfer_to ? transfer.transfer_to.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer date</th>
                                                <td>{formatDateTimeString(transfer.transfer_date)}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{transfer.product ? transfer.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{transfer.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Cost</th>
                                                <td>{transfer.unit_cost}</td>
                                            </tr>
                                            <tr>
                                                <th>Value</th>
                                                <td>{transfer.value}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{transfer.created_by ? transfer.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTransfer}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Transfer */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                    {/* <Form.Control size="sm" type="text" placeholder="Choose origin branch" 
                                            name="transfer_from"
                                            value={transfer_from}
                                            onChange={(e) => setTransferFrom(e.target.value)}
                                            /> */}
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>FROM</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferFrom(e)} />
                                    {/* <Form.Control size="sm" type="text" placeholder="Choose origin branch" 
                                            name="transfer_from"
                                            value={transfer_from}
                                            onChange={(e) => setTransferFrom(e.target.value)}
                                            /> */}
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferTo(e)} />
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            defaultValue={transfer.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            defaultValue={transfer.unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editTransfer(transfer.id);}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
    else if (user.company !== null && user.is_superuser) {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Transfer-records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Transfer Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make Transfer</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Transfer From</th>
                                                    <th>Transfer To</th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Transfer Date</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListTransfers />
                                        </tbody>
                                        <tfoot>
                                                <tr>
                                                    <ReactPaginate
                                                        // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                        // nextLabel={<CustomPaginationButtons label="Next" />}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(companyTransfers.length / perPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>

                    {/* Make Transfer */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="row">
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>FROM</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferFrom(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferTo(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            value={unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit cost is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddTransfer}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Transfer Detail */}
                    <Modal show={showTransfer} onHide={handleCloseTransfer}>
                        <Modal.Header closeButton>
                            <Modal.Title>Transfer Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{transfer.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{transfer.company ? transfer.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer From</th>
                                                <td>{transfer.transfer_from ? transfer.transfer_from.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer To</th>
                                                <td>{transfer.transfer_to ? transfer.transfer_to.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer date</th>
                                                <td>{formatDateTimeString(transfer.transfer_date)}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{transfer.product ? transfer.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{transfer.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Cost</th>
                                                <td>{transfer.unit_cost}</td>
                                            </tr>
                                            <tr>
                                                <th>Value</th>
                                                <td>{transfer.value}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{transfer.created_by ? transfer.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTransfer}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Transfer */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>FROM</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferFrom(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferTo(e)} />
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            defaultValue={transfer.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            defaultValue={transfer.unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editTransfer(transfer.id);}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
    else {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Transfer-records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Transfer Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make Transfer</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Transfer From</th>
                                                    <th>Transfer To</th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Transfer Date</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListTransfers />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(branchTransfers.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>

                    {/* Make Transfer */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadCBranch} onChange={(e) => selectTransferTo(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadBranchProduct} onChange={(e) => selectProduct(e)} required />

                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            value={unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit cost is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddBranchTransfer}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Transfer Detail */}
                    <Modal show={showTransfer} onHide={handleCloseTransfer}>
                        <Modal.Header closeButton>
                            <Modal.Title>Transfer Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{transfer.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{transfer.company ? transfer.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer From</th>
                                                <td>{transfer.transfer_from ? transfer.transfer_from.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer To</th>
                                                <td>{transfer.transfer_to ? transfer.transfer_to.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Transfer date</th>
                                                <td>{formatDateTimeString(transfer.transfer_date)}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{transfer.product ? transfer.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{transfer.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Cost</th>
                                                <td>{transfer.unit_cost}</td>
                                            </tr>
                                            <tr>
                                                <th>Value</th>
                                                <td>{transfer.value}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{transfer.created_by ? transfer.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTransfer}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Transfer */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>TO</Form.Label>
                                    <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectTransferTo(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity"
                                            name="quantity"
                                            defaultValue={transfer.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Cost</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter product amount" 
                                            name="unit_cost"
                                            defaultValue={transfer.unit_cost}
                                            onChange={(e) => setUnitCost(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editTransfer(transfer.id);}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}

export default Transfer