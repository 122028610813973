import React, { useState, useEffect } from 'react'
import { Modal, Table, Form, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { searchCompany, searchCompanyBranch, } from '../../utils/API'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate'
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils';


function SalesReport() {

    const [sales, setSales] = useState([]);
    const [company, setCompany] =useState("")
    const [branch, setBranch] = useState("")
    const [date_from, setDateFrom] =useState("")
    const [date_to, setDateTo] =useState("")
    const [validated, setValidated] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showSale, setShowSale] = useState(false);
    const handleCloseSale = () => setShowSale(false);
    const handleShowSale = () => setShowSale(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
 
    const FilterReport = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else {

            let formField = new FormData()
            formField.append('company', company)
            formField.append('branch', branch)
            formField.append('date_from', date_from)
            formField.append('date_to', date_to)
    
            const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-report', {
                data: formField, }
            ).then((response) => {
                console.log(response.data);
                setSales(response.data);
                handleClose();
                setCompany('')
                setBranch('')
                setDateFrom('')
                setDateTo('')
            })
        }
            
        setValidated(true);
    }


    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectBranch = (e) => {
        setBranch(e.value)
    }

    const loadBranches = (inputValue, callback) => {
        if (company != null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedSales = sales.slice(offset, offset + perPage);
    // const paginatedSalesFilter = tableFilter.slice(offset, offset + perPage);

    const ListSales = () => {
        return paginatedSales.map((sale, sl) => {
            return (
            <tr key={sale.id}>
                <td scope='row'>{sl+1}</td>
                <td>{sale.company.name}</td>
                <td>{sale.branch.name}</td>
                <td>{sale.product.name}</td>
                <td>{formatAmountWithCommas(sale.unit_price)}</td>
                <td>{formatAmountWithCommas(sale.quantity)}</td>
                <td>{formatDateTimeString(sale.created_at)}</td>
                <td>
                    {formatAmountWithCommas(sale.total)}
                </td>
            </tr>
            )
        }
        )
    }

  return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                            <li className="breadcrumb-item active">Sales Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="row">
                            <div className="card-header col-sm-9">
                                <h3 className="card-title">Sales Report</h3>
                            </div>
                            <div className="card-header col-sm-3 text-right">
                                <Button variant="success" className="btn btn-sm" onClick={handleShow}>Filter Report</Button>
                            </div>
                        </div>
                    
                        <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Company</th>
                                                <th>Branch</th>
                                                <th>Product</th>
                                                <th>Unit price</th>
                                                <th>Quantity</th>
                                                <th>Created</th>
                                                <th>Total</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                            <ListSales />
                                    </tbody>
                                    <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(sales.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                </table>
                        </div>
                    
                    </div>
                </div>
            </section>

                {/* Make Sales */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated}>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Company</Form.Label>
                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                <Form.Control.Feedback type="invalid">
                                    Company is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Branch</Form.Label>
                                <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} required />
                                <Form.Control.Feedback type="invalid">
                                    Branch is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>From date</Form.Label>
                                <Form.Control size="sm" type="date" placeholder="Enter from date"
                                        name="date_from"
                                        value={date_from}
                                        onChange={(e) => setDateFrom(e.target.value)}
                                        required
                                        />
                                <Form.Control.Feedback type="invalid">
                                    From date is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>To date</Form.Label>
                                <Form.Control size="sm" type="date" placeholder="Enter To date"
                                        name="date_to"
                                        value={date_to}
                                        onChange={(e) => setDateTo(e.target.value)}
                                        required
                                        />
                                <Form.Control.Feedback type="invalid">
                                    To date is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="success" onClick={FilterReport}>
                        Submit
                    </Button>
                    </Modal.Footer>
                </Modal>

        </div>
  )
}

export default SalesReport