import React, { useState, useEffect } from 'react'
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString } from '../../utils/DateTimeUtils';


function Supplier() {

    const [suppliers, setSuppliers] = useState([]);
    const [first_name, setFirstName] =useState("")
    const [last_name, setLastName ] = useState("")
    const [email, setEmail] =useState("")
    const [phone_number, setPhoneNumber] = useState("")
    const [supplier, setSupplier] = useState("")
    const [validated, setValidated] = useState(false)
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(suppliers)
    const [tableFilter, setTableFilter] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showSupplier, setShowSupplier] = useState(false);
    const handleCloseSupplier = () => setShowSupplier(false);
    const handleShowSupplier = () => setShowSupplier(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    const getSuppliers = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/suppliers', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setSuppliers(response.data);
    }

    const getSupplierById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/supplier/' + id, {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        })
        console.log(data)
        setSupplier(data.data)
    }

    const AddSupplier = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (first_name !== '' && last_name !== '' && phone_number !== '' && email !== '') {

            let formField = new FormData()
            formField.append('first_name', first_name)
            formField.append('last_name', last_name)
            formField.append('email', email)
            formField.append('phone_number', phone_number)

            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/suppliers',
                headers : {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getSuppliers();
                handleClose();
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
            })
        }
        setValidated(true);
    }

    const editSupplier = async (id) => {
        
        let formField = new FormData()
        formField.append('first_name', first_name)
        formField.append('last_name', last_name)
        formField.append('email', email)
        formField.append('phone_number', phone_number)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/supplier/' + id),
            headers : {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
            data: formField
        }).then((response) => {
            console.log(response)
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhoneNumber('');
            getSuppliers();
        })
    }

    const deleteSupplier = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/supplier/' + id, {
            headers : {
            Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
          },
        })
        console.log(response)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getSuppliers();
    }

    const filterSuppliers = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = suppliers.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...suppliers])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedSuppliers = suppliers.slice(offset, offset + perPage);
    const paginatedSuppliersFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getSuppliers();
    }, [])

    const ListSuppliers = () => {
        return value.length > 0 ? paginatedSuppliersFilter.map((supplier, sp) => {
            return (
            <tr key={supplier.id}>
                <td scope='row'>{sp+1}</td>
                <td>{supplier.first_name}</td>
                <td>{supplier.last_name}</td>
                <td>{supplier.email}</td>
                <td>{supplier.phone_number}</td>
                <td>
                    <Link  to="/suppliers" onClick={() => { getSupplierById(supplier.id); handleShowSupplier(); }} ><i className="fas fa-eye text-secondary" /></Link>
                    <Link  to="/suppliers" onClick={() => { getSupplierById(supplier.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                    <Link  to="/suppliers" onClick={() => deleteSupplier(supplier.id)} ><i className="fa fa-trash text-danger" /></Link>
                </td>
            </tr>
            )
        }
        )
        :
        paginatedSuppliers.map((supplier, sp) => {
            return (
            <tr key={supplier.id}>
                <td scope='row'>{sp+1}</td>
                <td>{supplier.first_name}</td>
                <td>{supplier.last_name}</td>
                <td>{supplier.email}</td>
                <td>{supplier.phone_number}</td>
                <td>
                    <Link  to="/suppliers" onClick={() => { getSupplierById(supplier.id); handleShowSupplier(); }} ><i className="fas fa-eye text-secondary" /></Link>
                    <Link  to="/suppliers" onClick={() => { getSupplierById(supplier.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                    <Link  to="/suppliers" onClick={() => deleteSupplier(supplier.id)} ><i className="fa fa-trash text-danger" /></Link>
                </td>
            </tr>
            )
        }
        )
    }

  return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                            <li className="breadcrumb-item active">Suppliers</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="row">
                            <div className="card-header col-sm-9">
                                <h3 className="card-title">Suppliers</h3>
                            </div>
                            <div className="card-header col-sm-3 text-right">
                                <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Supplier</Button>
                            </div>
                        </div>
                    
                        <div className="card-body">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                    placeholder="search"
                                    aria-label="search"
                                    aria-describedby="basic-addon1"
                                    value = {value}
                                    onChange={filterSuppliers}
                                    />
                                </InputGroup>
                                <table className="table table-bordered">
                                    <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Phone number</th>
                                                <th>Action</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <ListSuppliers />
                                    </tbody>
                                    <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(suppliers.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                    </tfoot>
                                </table>
                        </div>
                    
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Supplier</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    <Form.Group className="mb-2">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter first name"
                            name="first_name"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            />
                        <Form.Control.Feedback type="invalid">
                            First name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter last name"
                            name="last_name"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                                />
                        <Form.Control.Feedback type="invalid">
                            Last name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                        <Form.Control.Feedback type="invalid">
                            Email is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter phone number"
                                name="phone_number"
                                value={phone_number}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                                />
                        <Form.Control.Feedback type="invalid">
                            Phone number is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={AddSupplier}>
                Submit
            </Button>
            </Modal.Footer>
            </Modal>

            {/* Supplier Detail */}
        <Modal show={showSupplier} onHide={handleCloseSupplier}>
            <Modal.Header closeButton>
                <Modal.Title>Supplier Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>ID</th>
                                    <td>{supplier.id}</td>
                                </tr>
                                <tr>
                                    <th>First Name</th>
                                    <td>{supplier.first_name}</td>
                                </tr>
                                <tr>
                                    <th>Last Name</th>
                                    <td>{supplier.last_name}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{supplier.email}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number</th>
                                    <td>{supplier.phone_number}</td>
                                </tr>
                                <tr>
                                    <th>Created</th>
                                    <td>{formatDateTimeString(supplier.created_at)}</td>
                                </tr>
                            </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSupplier}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>


            {/* Edit Supplier */}
            <Modal show={showEdit} onHide={handleCloseEdit}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Supplier</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-2">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control size="sm" type="text"
                            name="first_name"
                            defaultValue={supplier.first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                                />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control size="sm" type="text"
                            name="last_name"
                            defaultValue={supplier.last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control size="sm" type="email" 
                            name="email"
                            defaultValue={supplier.email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control size="sm" type="text" 
                            name="phone_number"
                            defaultValue={supplier.phone_number}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={() => {editSupplier(supplier.id); handleCloseEdit();}}>
                Update
            </Button>
            </Modal.Footer>
            </Modal>
        </div>
  )
}

export default Supplier