import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
        <footer className="main-footer">
            <strong>Copyright © 2022 <Link to="/">smart-mauzo</Link>.</strong>
            All rights reserved.
            <div className='float-right d-none d-sm-inline-block'>
                <b>Version</b> 3.1.0
            </div>
        </footer>

        <aside className='control-sidebar control-sidebar-dark'>
        </aside>

    </div>
  )
}

export default Footer