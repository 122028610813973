import React, { useState, useEffect } from 'react'
import { Modal, Table, Form, Button, InputGroup } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { searchCompany, searchCompanyBranch, searchBranchProduct, searchBProduct } from '../../utils/API'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils';


function Sales() {

    const [sales, setSales] = useState([]);
    const [companySales, setCompanySales] = useState([]);
    const [branchSales, setBranchSales] = useState([]);
    const [company, setCompany] =useState("")
    const [branch, setBranch] = useState("")
    const [product, setProduct] =useState("")
    const [unit_price, setUnitPrice] =useState("")
    const [quantity, setQuantity] =useState("")
    const [sale, setSale] = useState("")
    const [validated, setValidated] = useState(false)
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(sales)
    const [companyDataSource, setCompanyDataSource] = useState(companySales)
    const [branchDataSource, setBranchDataSource] = useState(branchSales)
    const [tableFilter, setTableFilter] = useState([])
    const user = JSON.parse(localStorage.getItem('user'))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showSale, setShowSale] = useState(false);
    const handleCloseSale = () => setShowSale(false);
    const handleShowSale = () => setShowSale(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    // Get all sales -- Admin
    const getSales = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-records', {
                headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
        });
        console.log(response.data);
        setSales(response.data);
    }

    // Get sales on company of a loggedIn user
    const getCompanySales = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-sales', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setCompanySales(response.data.data);
    }

    // Get sales on branch 0f a loggedIn user
    const getBranchSales = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-sales', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setBranchSales(response.data.data);
    }

    // Get sales by ID
    const getSalesById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-record/' + id, {
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
        })
        console.log(data)
        setSale(data.data)
    }

    const AddSales = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (company !== '' && branch !== '' && product !== '' && unit_price !== '' && quantity !== '') {

            let formField = new FormData()
            formField.append('company', company)
            formField.append('branch', branch)
            formField.append('product', product)
            formField.append('unit_price', unit_price)
            formField.append('quantity', quantity)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-records',
                headers : {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                else if (response.data.status === 401) {
                    toast.error(response.data.details)
                }
                getSales();
                handleClose();
                setCompany('')
                setBranch('')
                setProduct('')
                setUnitPrice('')
                setQuantity('')
            })
        }
        setValidated(true);
    }

    const AddCompanySales = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (branch !== '' && product !== '' && unit_price !== '' && quantity !== '') {

            let formField = new FormData()
            formField.append('branch', branch)
            formField.append('product', product)
            formField.append('unit_price', unit_price)
            formField.append('quantity', quantity)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/company-sales',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                else if (response.data.status === 401) {
                    toast.error(response.data.details)
                }
                getCompanySales();
                handleClose();
                setBranch('')
                setProduct('')
                setUnitPrice('')
                setQuantity('')
            })
        }
        setValidated(true);
    }

    const AddBranchSales = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (product !== '' && unit_price !== '' && quantity !== '') {

            let formField = new FormData()
            formField.append('product', product)
            formField.append('unit_price', unit_price)
            formField.append('quantity', quantity)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-sales',
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                else if (response.data.status === 401) {
                    toast.error(response.data.details)
                }
                getBranchSales();
                handleClose();
                setProduct('')
                setUnitPrice('')
                setQuantity('')
            })
        }
        setValidated(true);
    }

    const editSales = async (id) => {
        
        let formField = new FormData()
        formField.append('company', company)
        formField.append('branch', branch)
        formField.append('product', product)
        formField.append('unit_price', unit_price)
        formField.append('quantity', quantity)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-record/' + id),
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
            data: formField
        }).then((response) => {
            console.log(response)
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            setCompany('')
            setBranch('')
            setProduct('')
            setUnitPrice('')
            setQuantity('')
            getSales();
        })
    }

    const deleteSales = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/sales-record/' + id, {
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
        })
        console.log(response)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getSales();
    }

    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectBranch = (e) => {
        setBranch(e.value)
    }

    const loadBranches = (inputValue, callback) => {
        if (company !== null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const loadBranchProduct = (inputValue, callback) => {

        searchBProduct(product, inputValue).then((response) => {
          console.log(response.data)
          const re = response.data.map((data) => {
            console.log(data)
            return {
              value: data.id,
              label: data.name,
            }
          })
          console.log(re)
          callback(re)
        })
  }

    const filterSales = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = sales.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...sales])
        }
    }


    const filterCompanySales = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = companySales.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setCompanyDataSource([...companySales])
        }
    }

    const filterBranchSales = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = branchSales.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setBranchDataSource([...branchSales])
        }
    }

    const selectProduct = (e) => {
        setProduct(e.value)
    }

    const loadProduct = (inputValue, callback) => {
        if (branch !== null) {
          searchBranchProduct(branch, inputValue).then((response) => {
            console.log('Products')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedSales = sales.slice(offset, offset + perPage);
    const paginatedCompanySales = companySales.slice(offset, offset + perPage);
    const paginatedBranchSales = branchSales.slice(offset, offset + perPage);
    const paginatedSalesFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getSales();
        getCompanySales();
        getBranchSales();
    }, [])

    const ListSales = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        console.log(companySales)
        
        if (user.is_superuser) {

            return value.length > 0 ? paginatedSalesFilter.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedSales.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else if (user.company !== null && user.is_superuser) {

            return value.length > 0 ? paginatedSalesFilter.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedCompanySales.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else {

            return value.length > 0 ? paginatedSalesFilter.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedBranchSales.map((sale, sl) => {
                return (
                <tr key={sale.id}>
                    <td scope='row'>{sl+1}</td>
                    <td>{sale.branch.name}</td>
                    <td>{sale.product.name}</td>
                    <td>{formatAmountWithCommas(sale.unit_price)}</td>
                    <td>{formatAmountWithCommas(sale.quantity)}</td>
                    <td>{formatDateTimeString(sale.created_at)}</td>
                    <td>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowSale(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/sales" onClick={() => { getSalesById(sale.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/sales" onClick={() => deleteSales(sale.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
    }

    if (user.is_superuser) {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Sales Records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Sales Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make sales</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                        placeholder="search"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                        value = {value}
                                        onChange={filterSales}
                                        />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Branch</th>
                                                    <th>Product</th>
                                                    <th>Unit price</th>
                                                    <th>Quantity</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListSales />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(sales.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
    
                    {/* Make Sales */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make sales</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Company is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Branch is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Product is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            value={unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddSales}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
                    {/* Sales Detail */}
                    <Modal show={showSale} onHide={handleCloseSale}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sales Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{sale.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{sale.company ? sale.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Branch</th>
                                                <td>{sale.branch ? sale.branch.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{sale.product ? sale.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{sale.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Price</th>
                                                <td>{sale.unit_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>{sale.total}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{formatDateTimeString(sale.created_at)}</td>
                                            </tr>
                                            <tr>
                                                <th>Staff</th>
                                                <td>{sale.created_by ? sale.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSale}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
    
                    {/* Edit Sales record */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Sales record</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} />
                                </Form.Group>
                                </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            defaultValue={sale.unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            defaultValue={sale.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editSales(sale.id); handleCloseEdit();}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
      )
    }

    else if (user.company !== null && user.is_superuser) {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Sales Records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Sales Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make sales</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                        placeholder="search"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                        value = {value}
                                        onChange={filterCompanySales}
                                        />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Branch</th>
                                                    <th>Product</th>
                                                    <th>Unit price</th>
                                                    <th>Quantity</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListSales />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(companySales.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
    
                    {/* Make Sales */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make sales</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Branch is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Product is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            value={unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddCompanySales}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
                    {/* Sales Detail */}
                    <Modal show={showSale} onHide={handleCloseSale}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sales Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{sale.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{sale.company ? sale.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Branch</th>
                                                <td>{sale.branch ? sale.branch.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{sale.product ? sale.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{sale.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Price</th>
                                                <td>{sale.unit_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>{sale.total}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{formatDateTimeString(sale.created_at)}</td>
                                            </tr>
                                            <tr>
                                                <th>Staff</th>
                                                <td>{sale.created_by ? sale.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSale}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
    
                    {/* Edit Sales record */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Sales record</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Branch</Form.Label>
                                    <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            defaultValue={sale.unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            defaultValue={sale.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editSales(sale.id); handleCloseEdit();}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
      )
    }

    else {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Sales Records</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Sales Records</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Make sales</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                        placeholder="search"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                        value = {value}
                                        onChange={filterBranchSales}
                                        />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Branch</th>
                                                    <th>Product</th>
                                                    <th>Unit price</th>
                                                    <th>Quantity</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <ListSales />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(branchSales.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                        </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
    
                    {/* Make Sales on logged in user branch*/}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make sales</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={validated}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadBranchProduct} onChange={(e) => selectProduct(e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Product is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            value={unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Unit price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                            />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={AddBranchSales}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
                    {/* Sales Detail */}
                    <Modal show={showSale} onHide={handleCloseSale}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sales Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td>{sale.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Company</th>
                                                <td>{sale.company ? sale.company.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Branch</th>
                                                <td>{sale.branch ? sale.branch.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <td>{sale.product ? sale.product.name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Quantity</th>
                                                <td>{sale.quantity}</td>
                                            </tr>
                                            <tr>
                                                <th>Unit Price</th>
                                                <td>{sale.unit_price}</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>{sale.total}</td>
                                            </tr>
                                            <tr>
                                                <th>Created</th>
                                                <td>{formatDateTimeString(sale.created_at)}</td>
                                            </tr>
                                            <tr>
                                                <th>Staff</th>
                                                <td>{sale.created_by ? sale.created_by.email : ''}</td>
                                            </tr>
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSale}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
    
    
                    {/* Edit Sales record */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Sales record</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>
                                <Form.Group className="mb-2">
                                    <Form.Label>Product</Form.Label>
                                    <AsyncSelect loadOptions={loadProduct} onChange={(e) => selectProduct(e)} />
                                </Form.Group>
                                <div className='row'>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Unit Price</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter unit price"
                                            name="unit_price"
                                            defaultValue={sale.unit_price}
                                            onChange={(e) => setUnitPrice(e.target.value)}
                                            />
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter quantity" 
                                            name="quantity"
                                            defaultValue={sale.quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            />
                                </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" onClick={() => {editSales(sale.id); handleCloseEdit();}}>
                            Update
                        </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
      )
    }
}

export default Sales