import React, {useState, useEffect} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import { searchExpensesType } from '../../utils/API';
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils'
import { searchCompany, searchCompanyBranch, searchBranch } from '../../utils/API';




const Expenses = () => {

    const [expenses_, setExpenses] = useState([]);
    const [companyExpenses, setCompanyExpenses] = useState([])
    const [branchExpenses, setBranchExpenses] = useState([])
    const [company, setCompany] =useState("")
    const [branch, setBranch] =useState("")
    const [expense, setExpense] = useState("");
    const [type, setType] = useState("");
    const [quantity, setQuantity] = useState("");
    const [amount, setAmount] = useState("");
    const [expense_note, setExpenseNote] = useState("");
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(expenses_)
    const [tableFilter, setTableFilter] = useState([])
  
  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [showExpenses, setShowExpenses] = useState(false);
    const handleCloseExpenses = () => setShowExpenses(false);
    const handleShowExpenses = () => setShowExpenses(true);
  
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
  
      // get all expenses -- Admin part
        const getAllExpenses = async () => {
            const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses', {
                headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
            const formattedExpenses = response.data
            response.data = value.match(/\d{1,3}/g, ',');
            setExpenses(formattedExpenses)
            console.log(formattedExpenses);
            // setExpenses(response.data);
        }
    
        const getExpenseById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses/' + id)
        console.log(data.data)
        setExpense(data.data)
        }
    
        const AddExpense = async (event) => {
            // const form = event.currentTarget;
            // if (form.checkValidity() === false) {
            //   event.preventDefault();
            //   event.stopPropagation();
            // }
            // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {
        
                let formField = new FormData()
                formField.append('type', type)
                formField.append('quantity', quantity)
                formField.append('amount', amount)
                formField.append('expense_note', expense_note)
        
                await axios({
                    method: 'post',
                    url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses',
                    headers: {
                          Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                        },
                    data: formField
                }).then((response) => {
                    console.log(response)
                    if (response.data.status === 200) {
                        toast.success(response.data.details)
                    }
                    else if (response.data.status === 400) {
                        toast.error(response.data.details)
                    }
                    getAllExpenses();
                    handleClose();
                    setType('')
                    setQuantity('')
                    setAmount('')
                    setExpenseNote('')
                })
            // }
            // setValidated(true);
        }

        const updateExpense = async (id) => {

            // const form = event.currentTarget;
            // if (form.checkValidity() === false) {
            //   event.preventDefault();
            //   event.stopPropagation();
            // }
            // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {
        

            let formField = new FormData()
            formField.append('type', type)
            formField.append('quantity', quantity)
            formField.append('amount', amount)
            formField.append('expense_note', expense_note)

            await axios({
                method: 'put',
                url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses/' + id),
            //  headers: {
            //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            //     },
                data: formField
            }).then((response) => {
                if (response.data.status == 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status == 400) {
                    toast.error(response.data.details)
                }
                getAllExpenses();
                handleClose();
                setType('')
                setQuantity('')
                setAmount('')
                setExpenseNote('')
            })
        }

        // get expenses on company of a loggedIn user
        // const getCompanyExpenses = async () => {
        //     const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-expenses', {
        //         headers: {
        //           Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        //         },
        //     });
        //     console.log(response.data);
        //     setCompanyExpenses(response.data.data);
        // }
    
         // get expenses on branch of a loggedIn user
        const getBranchExpenses = async () => {
            const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses', {
                headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
            console.log(response.data);
            setBranchExpenses(response.data.data);
        }

        const AddBranchExpense = async (event) => {

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            }
            else if (type !== '' &&
            quantity !== '' && amount !== '' && expense_note !== '') {
                let formField = new FormData()
                formField.append('type', type)
                formField.append('quantity', quantity)
                formField.append('amount', amount)
                formField.append('expense_note', expense_note)

                await axios({
                    method: 'post',
                    url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses',
                    headers : {
                        Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                    },
                    data: formField
                }).then((response) => {
                    if (response.data.status === 200) {
                        toast.success(response.data.details)
                    }
                    else if (response.data.status === 400) {
                        toast.error(response.data.details)
                    }
                    getBranchExpenses();
                    handleClose();
                    setType('')
                    setQuantity('')
                    setAmount('')
                    setExpenseNote('')
                })
            }
            setValidated(true);
        }

        const editBranchExpense = async (id) => {
            
            let formField = new FormData()
            formField.append('type', type)
            formField.append('quantity', quantity)
            formField.append('amount', amount)
            formField.append('expense_note', expense_note)

            await axios({
                method: 'put',
                url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses/' + id),
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                handleCloseEdit();
                getBranchExpenses();
                handleClose();
                setType('')
                setQuantity('')
                setAmount('')
                setExpenseNote('')
            })
        }


        const selectExpensesType = (e) => {
            setType(e.value)
        }

        const loadExpensesType = (inputValue, callback) => {
        searchExpensesType(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                    }
            })
            console.log(re)
            callback(re)
        })
        }
  
        const deleteExpenses = async (id) => {
            const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses/' + id)
            console.log(response.data)
            // if (response.data.status == 200) {
            //     toast.success(response.data.details)
            // }
            // else if (response.data.status == 400) {
            //     toast.error(response.data.details)
            // }
            getAllExpenses();
            }

            const selectCompany = (e) => {
                setCompany(e.value)
            }
        
            const loadCompany = (inputValue, callback) => {
                searchCompany(inputValue).then((response) => {
                    console.log(response)
                    const re = response.results.map((data) => {
                        console.log(data)
                        return {
                            value: data.id,
                            label: data.name,
                          }
                    })
                    console.log(re)
                    callback(re)
                })
            }
        
            const selectBranch = (e) => {
                setBranch(e.value)
            }
        
            const loadBranches = (inputValue, callback) => {
                if (company !== null) {
                  searchCompanyBranch(company, inputValue).then((response) => {
                    console.log('Branches')
                    console.log(response)
                    const re = response.map((data) => {
                      console.log(data)
                      return {
                        value: data.id,
                        label: data.name,
                      }
                    })
                    console.log(re)
                    callback(re)
                  })
                } else {
                  callback([])
                }
            }

    // const handleInputChange = (e) => {

    //     const value = e.target.value;

    //     const formattedAmount = value.match(/\d{1,3}/g, ',');
    //     setAmount(formattedAmount)
    
    // }

    const handleInputChange = (e) => {
        const value = e.target.value;
      
        // Remove existing commas (if any) and split the number into integer and decimal parts
        const [integerPart, decimalPart] = value.replace(/,/g, '').split('.');
      
        // Format the integer part with commas every three digits from the right
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
        // Combine the formatted integer and decimal parts (if any)
        const formattedValue = decimalPart
          ? `${formattedIntegerPart}.${decimalPart}`
          : formattedIntegerPart;
      
        setAmount(formattedValue);

    }

    const filterExpenses = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = expenses_.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...expenses_])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedExpenses = expenses_.slice(offset, offset + perPage);
    // const paginatedCompanyExpenses = companyExpenses.slice(offset, offset + perPage);
    // const paginatedBranchExpenses = branchExpenses.slice(offset, offset + perPage);
    const paginatedExpensesFilter = tableFilter.slice(offset, offset + perPage);
  
    useEffect(() => {
        getAllExpenses();
        getBranchExpenses();
      }, [])

      const ExpensesList = () => {

        return value.length > 0 ? paginatedExpensesFilter.map((expenses_, xp) => {
            return (
                <tr key={expenses_.id}>
                    <td scope='row'>{xp+1}</td>
                    <td>{expenses_.type.name}</td>
                    <td>{formatAmountWithCommas(expenses_.quantity)}</td>
                    <td>{formatAmountWithCommas(expenses_.amount)}</td>
                    <td>{expenses_.expense_note}</td>
                    <td>{formatDateTimeString(expenses_.created_at)}</td>
                    <td>
                        <Link  to="/expenses" onClick={() => { getExpenseById(expenses_.id); handleShowExpenses(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/expenses" onClick={() => { getExpenseById(expenses_.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/expenses" onClick={() => deleteExpenses(expenses_.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
            )
        }
        )
        :


        paginatedExpenses.map((expenses_, xp) => (

          <tr key={expenses_.id}>
              <td scope='row'>{xp+1}</td>
              <td>{expenses_.type.name}</td>
              <td>{formatAmountWithCommas(expenses_.quantity)}</td>
              <td>{formatAmountWithCommas(expenses_.amount)}</td>
              <td>{expenses_.expense_note}</td>
              <td>{formatDateTimeString(expenses_.created_at)}</td>
              <td>
                  <Link  to="/expenses" onClick={() => { getExpenseById(expenses_.id); handleShowExpenses(); }} ><i className="fas fa-eye text-secondary" /></Link>
                  <Link  to="/expenses" onClick={() => { getExpenseById(expenses_.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                  <Link  to="/expenses" onClick={() => deleteExpenses(expenses_.id)} ><i className="fa fa-trash text-danger" /></Link>
              </td>
          </tr>
        )
          )
      }
      
      return (

    <div>
        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                        <li className="breadcrumb-item active">Expenses</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="container-fluid">
                <div className="card">
                    <div className="row">
                        <div className="card-header col-sm-9">
                            <h3 className="card-title">Expenses</h3>
                        </div>
                        <div className="card-header col-sm-3 text-right">
                            <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Expenses</Button>
                        </div>
                    </div>
                
                    <div className="card-body">
                            <InputGroup className="mb-3">
                                <Form.Control
                                placeholder="search"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value = {value}
                                onChange={filterExpenses}
                                />
                            </InputGroup>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Expense Type</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    <th>Expenses Note</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <ExpensesList />
                                </tbody>
                                <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(expenses_.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                </tfoot>
                            </table>
                    </div>
                
                </div>
            </div>
        </section>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                    <Form noValidate validated={validated}>
                        <div className = "row">
                          <Form.Group className="mb-2 col-md-6">
                              <Form.Label>Expenses Type</Form.Label>
                              <AsyncSelect loadOptions={loadExpensesType} onChange={(e) => selectExpensesType(e)} required />
                              {/* <Form.Control size="sm" type="text"
                                  name = "type"
                                  value = {type}
                                  placeholder="Enter Type"
                                  onChange={(e) => setType(e.target.value)}
                                  required
                                  /> */}
                              <Form.Control.Feedback type="invalid">
                                  Expenses type is required
                              </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-2 col-md-6">
                              <Form.Label>Quantity</Form.Label>
                              <Form.Control size="sm" type="text" 
                                  name = "quantity"
                                  value = {quantity}
                                  placeholder="Enter Quantity"
                                  onChange={(e) => setQuantity(e.target.value)}
                                  required
                                  />
                              <Form.Control.Feedback type="invalid">
                                  Quantity is required
                              </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                          <Form.Group className="mb-2">
                              <Form.Label>Amount</Form.Label>
                              <Form.Control size="sm" type="text" 
                                  name = "amount"
                                  value = {amount}
                                  placeholder="Enter amount"
                                  onChange={(e) => setAmount(e.target.value)}
                                // onChange={handleInputChange}
                                  required
                                  />
                              <Form.Control.Feedback type="invalid">
                                  Amount is required
                              </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-2">
                              <Form.Label>Expenses Note</Form.Label>
                              <Form.Control as="textarea" rows={3}
                                  name = "expense_note"
                                  value = {expense_note}
                                  placeholder="Enter Expenses note"
                                  onChange={(e) => setExpenseNote(e.target.value)}
                                  required
                                  />
                              <Form.Control.Feedback type="invalid">
                                  Expenses note is required
                              </Form.Control.Feedback>
                          </Form.Group>
                                 {/* <Form.Control size="sm" type="hidden"
                                  name = "created_by"
                                  value = {created_by}
                                  onChange={(e) => setCreatedBy(e.target.value)}
                                  />
                          </Form.Group> */}
                      </Form>
                  </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={AddExpense}>
            Submit
        </Button>
        </Modal.Footer>
        </Modal>

        {/* Expenses Detail */}
        <Modal show={showExpenses} onHide={handleCloseExpenses}>
        <Modal.Header closeButton>
        <Modal.Title>Expense Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table className="table table-bordered">
                    <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{expense.id}</td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>{expense.type ? expense.type.name : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Quantity</th>
                                        <td>{expense.quantity}</td>
                                    </tr>
                                    <tr>
                                        <th>Amount</th>
                                        <td>{expense.amount}</td>
                                    </tr>
                                    <tr>
                                        <th>Expense Note</th>
                                        <td>{expense.expense_note}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{expense.status ? expense.status.status_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Created</th>
                                        <td>{formatDateTimeString(expense.created_at)}</td>
                                    </tr>
                    </tbody>
        </Table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseExpenses}>
        Close
        </Button>
        </Modal.Footer>
        </Modal>


        {/* Edit Expenses */}
        <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
        <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
                    <Modal.Body>
                            <Form noValidate validated={validated}>
                            <div className = "row">
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Expenses Type</Form.Label>
                                    <AsyncSelect loadOptions={loadExpensesType} onChange={(e) => selectExpensesType(e)} required />
                                    {/* <Form.Control size="sm" type="text"
                                        name = "type"
                                        value = {type}
                                        placeholder="Enter Type"
                                        onChange={(e) => setType(e.target.value)}
                                        required
                                        /> */}
                                    <Form.Control.Feedback type="invalid">
                                        Expenses type is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2 col-md-6">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control size="sm" type="text" 
                                        name = "quantity"
                                        defaultValue = {expense.quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                        required
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        Quantity is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                                <Form.Group className="mb-2">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control size="sm" type="text" 
                                        name = "amount"
                                        defaultValue = {expense.amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        Amount is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Expenses Note</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        name = "expense_note"
                                        defaultValue = {expense.expense_note}
                                        onChange={(e) => setExpenseNote(e.target.value)}
                                        required
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        Expenses note is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                        {/* <Form.Control size="sm" type="hidden"
                                        name = "created_by"
                                        value = {created_by}
                                        onChange={(e) => setCreatedBy(e.target.value)}
                                        />
                                </Form.Group> */}
                            </Form>
                    </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={() => {updateExpense(expense.id); handleCloseEdit();}}>
        Update
        </Button>
        </Modal.Footer>
        </Modal>
    </div>

      )
}

export default Expenses