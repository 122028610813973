import React, { useState, useEffect } from 'react'
import { Spinner, Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { searchCompany, searchCompanyBranch } from '../../utils/API';
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString } from '../../utils/DateTimeUtils';
import { Config } from '../../utils/Config';

function Branch() {

    const [branches, setBranches] = useState([]);
    const [companyBranches, setCompanyBranches] = useState([]);
    const [name, setName] =useState("")
    const [location, setLocation ] = useState("")
    const [company, setCompany ] = useState("")
    const [branch, setBranch] = useState("")
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(branches)
    const [tableFilter, setTableFilter] = useState([])
    const [companydataSource, setCompanyDataSource] = useState(companyBranches)
    const [validated, setValidated] = useState(false)
    const [rowBranch, setRowBranch] = useState([])
    const [Loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showBranch, setShowBranch] = useState(false);
    const handleCloseBranch = () => setShowBranch(false);
    const handleShowBranch = () => setShowBranch(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    // const [showEditCompany, setShowEditCompany] = useState(false);
    // const handleCloseEditCompany = () => setShowEditCompany(false);
    // const handleShowEditCompany = () => setShowEditCompany(true);

    const {id} = useParams();

    const user = JSON.parse(localStorage.getItem('user'))

    // Get all registered branches -- Admin
    const getBranches = async () => {
        try {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 7000));
            const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branches', {
                headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
            console.log(response.data);
            setBranches(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    //  Get branches on company of a loggedIn user
    const getCompanyBranches = async () => {
        try {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 7000));
            const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-branches', {
                headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
            console.log(response.data);
            setCompanyBranches(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const getBranchById = async (id) => {
        try {
            setLoading(true);
            const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch/' + id , {
                headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            })
            console.log(data)
            setBranch(data.data)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }


    const AddBranch = async (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false || !company || !name || !location) {
            setValidated(true);
            return;
        }
    
        try {
            setLoading(true);
    
            const formField = new FormData();
            formField.append('company', company);
            formField.append('name', name);
            formField.append('location', location);
    
            const response = await axios.post('https://master-business-application-772r7.ondigitalocean.app/api/v1/branches', formField, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
    
            console.log(response);
            if (response.data.status === 200) {
                toast.success(response.data.details);
                handleClose();
                getBranches();
                setName('');
                setLocation('');
                setCompany('');
            } else if (response.data.status === 400) {
                toast.error(response.data.details);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };    

    const editBranch = async (id) => {
        try {
            setLoading(true);
    
            const formField = new FormData();
            formField.append('company', company);
            formField.append('name', name);
            formField.append('location', location);
    
            const response = await axios.put(`https://master-business-application-772r7.ondigitalocean.app/api/v1/branch/${id}`, formField, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
    
            console.log(response);
            if (response.data.status === 200) {
                toast.success(response.data.details);
                setName('');
                setLocation('');
                setCompany('');
                getBranches();
            } else if (response.data.status === 400) {
                toast.error(response.data.details);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    

    const AddCompanyBranch = async (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
    
        try {
            setLoading(true);
    
            if (name !== '' && location !== '') {
                const formField = new FormData();
                formField.append('name', name);
                formField.append('location', location);
    
                const response = await axios.post('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-branches', formField, {
                    headers: {
                        Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                    },
                });
    
                console.log(response);
                if (response.data.status === 200) {
                    toast.success(response.data.details);
                    handleClose();
                    getCompanyBranches();
                    setName('');
                    setLocation('');
                } else if (response.data.status === 400) {
                    toast.error(response.data.details);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    


    const editCompanyBranch = async (id) => {
        try {
            setLoading(true);
    
            const formField = new FormData();
            formField.append('name', name);
            formField.append('location', location);
    
            const response = await axios.put(`https://master-business-application-772r7.ondigitalocean.app/api/v1/company-branches/${id}`, formField, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
    
            console.log(response);
            if (response.data.status === 200) {
                toast.success(response.data.details);
                handleCloseEdit();
                setName('');
                setLocation('');
                getCompanyBranches();
            } else if (response.data.status === 400) {
                toast.error(response.data.details);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    


    const deleteBranch = async (id) => {
        try {
            setLoading(true);
    
            const response = await axios.delete(`https://master-business-application-772r7.ondigitalocean.app/api/v1/branch/${id}`, {
                headers: {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            });
    
            console.log(response.data);
            if (response.data.status === 200) {
                toast.success(response.data.details);
                getBranches();
            } else if (response.data.status === 400) {
                toast.error(response.data.details);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    

    const selectCompany = (e) => {
        setCompany(e.value)
      }
    

    const loadOptions = async (inputValue, callback) => {
        try {
            const response = await searchCompany(inputValue);
            console.log(response);
    
            const re = response.results.map((data) => ({
                value: data.id,
                label: data.name,
            }));
    
            console.log(re);
            callback(re);
        } catch (error) {
            console.error(error);
        }
    };
    

    const filterBranches = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setValue(searchValue);
    
        if (searchValue !== "") {
            const filterTable = branches.filter(o => Object.values(o).some(value =>
                String(value).toLowerCase().includes(searchValue)
            ));
            setTableFilter([...filterTable]);
        } else {
            setDataSource([...branches]);
        }
    };
    


    const filterCompanyBranches = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setValue(searchValue);
    
        if (searchValue !== "") {
            const filterTable = companyBranches.filter(o => Object.values(o).some(value =>
                String(value).toLowerCase().includes(searchValue)
            ));
            setTableFilter([...filterTable]);
        } else {
            setCompanyDataSource([...companyBranches]);
        }
    };
    

    useEffect(() => {
        getBranches();
        getCompanyBranches();
    }, [])


    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedBranches = branches.slice(offset, offset + perPage);
    const paginatedCompanyBranches = companyBranches.slice(offset, offset + perPage);
    const paginatedBranchesFilter = tableFilter.slice(offset, offset + perPage);

    const ListBranches = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const branchesToDisplay = user.is_superuser
            ? value.length > 0 ? paginatedBranchesFilter : paginatedBranches
            : value.length > 0 ? paginatedBranchesFilter : paginatedCompanyBranches;
    
        return branchesToDisplay.map((branch, br) => {
            const formattedDate = formatDateTimeString(branch.created_at);
    
            return (
                <tr key={branch.id}>
                    <td scope='row'>{br + 1}</td>
                    <td>{branch.name}</td>
                    <td>{branch.location}</td>
                    <td>{branch.company.name}</td>
                    <td>{formattedDate}</td>
                    <td>
                        <Link to="/branches" onClick={() => { getBranchById(branch.id); handleShowBranch(); }}><i className="fas fa-eye text-secondary" /></Link>
                        <Link to="/branches" onClick={() => { getBranchById(branch.id); handleShowEdit(); }}><i className="fas fa-edit text-primary" /></Link>
                        <Link to="/branches" onClick={() => deleteBranch(branch.id)}><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
            );
        });
    };
    
    

    if (user.is_superuser) {
        return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Branches</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Branches</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Branch</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                                <Form.Control
                                                placeholder="search"
                                                aria-label="search"
                                                aria-describedby="basic-addon1"
                                                value = {value}
                                                onChange={filterBranches}
                                                />
                                            </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Branch</th>
                                                        <th>Location</th>
                                                        <th>Company</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {Loading ? (        
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                                ):
                                                (
                                                    <ListBranches />
                                                )
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <ReactPaginate
                                                        // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                        // nextLabel={<CustomPaginationButtons label="Next" />}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(branches.length / perPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </tr>
                                            </tfoot>
                                        </table>

                                </div>
                            
                            </div>
                        </div>
                    </section>

                    {/* Add new Branch  */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Branch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate onSubmit={AddBranch}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadOptions} onChange={(e) => selectCompany(e)} required />

                                    <Form.Control.Feedback type="invalid">
                                        Company is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter branch name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        Name is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control size="sm" type="text" 
                                        name="location"
                                        value={location} 
                                        placeholder="Enter branch Location"
                                        onChange={(e) => setLocation(e.target.value)}
                                        required
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        Location is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button type="submit" variant="success" disabled={Loading}>
                            {Loading ? 'Submitting ...' : 'Submit'}
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Branch Detail */}
                    <Modal show={showBranch} onHide={handleCloseBranch}>
                        <Modal.Header closeButton>
                            <Modal.Title>Branch Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="table table-bordered">
                                        <tbody>
                                        {
                                        Loading ?
                                         (        
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                                ):
                                            (
                                            <><tr>
                                                <th>ID</th>
                                                <td>{branch.id}</td>
                                            </tr><tr>
                                                    <th>Company</th>
                                                    <td>{branch.company ? branch.company.name : ''}</td>
                                                </tr><tr>
                                                    <th>branch</th>
                                                    <td>{branch.name}</td>
                                                </tr><tr>
                                                    <th>Location</th>
                                                    <td>{branch.location}</td>
                                                </tr><tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(branch.created_at)}</td>
                                                </tr></>
                                            )
                                            }
                                        </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseBranch}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Edit Branch */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Branch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate>
                                <Form.Group className="mb-2">
                                    <Form.Label>Company</Form.Label>
                                    <AsyncSelect loadOptions={loadOptions} onChange={(e) => selectCompany(e)} />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control size="sm" type="text"
                                        name="name"
                                        defaultValue={branch.name}
                                        onChange={(e) => setName(e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control size="sm" type="text" 
                                        name="location"
                                        defaultValue={branch.location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="success" disabled={Loading} onClick={() => {editBranch(branch.id); handleCloseEdit();}}>
                        {Loading ? 'Updating ...' : 'Update'}
                        </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
        )
    }
    else {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Branches</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-9">
                                    <h3 className="card-title">Branches</h3>
                                </div>
                                <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Branch</Button>
                                </div>
                            </div>
                        
                            <div className="card-body">
                                <InputGroup className="mb-3">
                                        <Form.Control
                                        placeholder="search"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                        value = {value}
                                        onChange={filterCompanyBranches}
                                        />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                    <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Branch</th>
                                                <th>Location</th>
                                                <th>Company</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                    {Loading ? (        
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                                ):
                                                (
                                                    <ListBranches />
                                                )
                                                }
                                    </tbody>
                                            <tfoot>
                                                <tr>
                                                    <ReactPaginate
                                                        // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                        // nextLabel={<CustomPaginationButtons label="Next" />}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(companyBranches.length / perPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </tr>
                                            </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>

                {/* Add new Branch  */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate>
                        <Form.Group className="mb-2">
                            <Form.Label>Name</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter branch name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control size="sm" type="text" 
                                name="location"
                                value={location} 
                                placeholder="Enter branch Location"
                                onChange={(e) => setLocation(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Location is required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" disabled={Loading} onClick={AddCompanyBranch}>
                {Loading ? 'Submitting ...' : 'Submit'}
                </Button>
                </Modal.Footer>
            </Modal>

                {/* Branch Detail */}
            <Modal show={showBranch} onHide={handleCloseBranch}>
                <Modal.Header closeButton>
                    <Modal.Title>Branch Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="table table-bordered">
                    <tbody>
                                        {
                                        Loading ?
                                         (        
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </td>
                                                </tr>
                                                ):
                                            (
                                            <><tr>
                                                <th>ID</th>
                                                <td>{branch.id}</td>
                                            </tr><tr>
                                                    <th>Company</th>
                                                    <td>{branch.company ? branch.company.name : ''}</td>
                                                </tr><tr>
                                                    <th>branch</th>
                                                    <td>{branch.name}</td>
                                                </tr><tr>
                                                    <th>Location</th>
                                                    <td>{branch.location}</td>
                                                </tr><tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(branch.created_at)}</td>
                                                </tr></>
                                            )
                                            }
                                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseBranch}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>


                {/* Edit Branch */}
                <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate>
                        <Form.Group className="mb-2">
                            <Form.Label>Name</Form.Label>
                            <Form.Control size="sm" type="text"
                                name="name"
                                defaultValue={branch.name}
                                onChange={(e) => setName(e.target.value)}
                                />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control size="sm" type="text" 
                                name="location"
                                defaultValue={branch.location}
                                onChange={(e) => setLocation(e.target.value)}
                                />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" disabled={Loading} onClick={() => {editCompanyBranch(branch.id);}}>
                {Loading ? 'Updating ...' : 'Update'}
                </Button>
                </Modal.Footer>
                </Modal>
            </div>
        )
  }
}

export default Branch