import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Dashboard from './components/Dashboard/Dashboard';
import Company from './components/Company/Company';
import Branch from './components/Branch/Branch';
import Products from './components/Products/Products';
import Sales from './components/Sales/Sales';
import Staff from './components/Staff/Staff';
import Supplier from './components/Supplier/Supplier';
import Deliveries from './components/Products/Deliveries';
import Transfer from './components/Products/Transfer';
import SalesReport from './components/Sales/SalesReport';
import Login from './components/Authentication/Login';
import DefaultLayout from './components/Layouts/Default';
import 'react-toastify/dist/ReactToastify.css';
import Expenses from './components/Expenses/Expenses';
import Expensestype from './components/Expenses/Expensestype';
import Status from './components/Status/Status';

function App() {
  return (
    <div>
        <Router>
                    <ToastContainer />
            <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<DefaultLayout />} >
                        <Route exact path="/home" element={<Dashboard />} />
                        <Route exact path="/companies" element={<Company />} />
                        <Route exact path="/branches" element={<Branch />} />
                        <Route exact path="/products" element={<Products />} />
                        <Route exact path="/sales" element={<Sales />} />
                        <Route exact path="/sales-report" element={<SalesReport />} />
                        <Route exact path="/staffs" element={<Staff />} />
                        <Route exact path="/suppliers" element={<Supplier />} />
                        <Route exact path="/stock-deliveries" element={<Deliveries />} />
                        <Route exact path="/stock-transfer" element={<Transfer />} />
                        <Route exact path="/expenses" element={<Expenses />} />
                        <Route exact path="/expenses-type" element={<Expensestype />} />
                        <Route exact path="/status" element={<Status />} />
                    </Route>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
