import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Table, Form, Button, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { searchCompany, searchCompanyBranch, searchBranch, searchSupplier } from '../../utils/API';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { formatDateTimeString, formatAmountWithCommas } from '../../utils/DateTimeUtils'


function Products() {

    const [products, setProducts] = useState([]);
    const [companyProducts, setCompanyProducts] = useState([])
    const [branchProducts, setBranchProducts] = useState([])
    const [company, setCompany] =useState("")
    const [branch, setBranch] =useState("")
    const [name, setName] =useState("")
    const [product_code, setProductCode] =useState("")
    const [description, setDescription ] = useState("")
    const [manufacturer, setManufacturer] =useState("")
    const [supplier, setSupplier ] = useState("")
    const [product, setProduct] = useState({})
    const [value, setValue] = useState("")
    const [tableFilter, setTableFilter] = useState([])
    const [dataSource, setDataSource] = useState(products)
    const [companyDataSource, setCompanyDataSource] = useState(companyProducts)
    const [branchDataSource, setBranchDataSource] = useState(branchProducts)
    const [validated, setValidated] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showProduct, setShowProduct] = useState(false);
    const handleCloseProduct = () => setShowProduct(false);
    const handleShowProduct = () => setShowProduct(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);


    const {id} = useParams();

    const user = JSON.parse(localStorage.getItem('user'))

    // get all products -- Admin part
    const getProducts = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/products', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setProducts(response.data);
    }

    // get products on company of a loggedIn user
    const getCompanyProducts = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-products', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setCompanyProducts(response.data.data);
    }

     // get products on branch of a loggedIn user
    const getBranchProducts = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-products', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setBranchProducts(response.data.data);
    }

    // Get products by ID
    const getProductById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/product/' + id, {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        })
        console.log(data)
        setProduct(data.data)
    }
    

    // filter all products part of Admin
    const filterProducts = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = products.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...products])
        }
    }

    // filter products on company of loggedIn user
    const filterCompanyProducts = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = companyProducts.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setCompanyDataSource([...companyProducts])
        }
    }

    // filter products on branch of loggedIn user
    const filterBranchProducts = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = branchProducts.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setBranchDataSource([...branchProducts])
        }
    }

    const AddProduct = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (company !== '' && branch !== '' && name !== '' &&
        product_code !== '' && description !== '' && manufacturer !== ''
        && supplier !== '') {
            let formField = new FormData()
            formField.append('company', company)
            formField.append('branch', branch)
            formField.append('name', name)
            formField.append('product_code', product_code)
            formField.append('description', description)
            formField.append('manufacturer', manufacturer)
            formField.append('supplier', supplier)

            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/products',
                headers: {
                      Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                    },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getProducts();
                handleClose();
                setBranch('')
                setCompany('')
                setName('')
                setProductCode('')
                setDescription('')
                setManufacturer('')
                setSupplier('')
            })
        }
        setValidated(true);
    }

    const editProduct = async (id) => {
        
        let formField = new FormData()
        formField.append('company', company)
        formField.append('branch', branch)
        formField.append('name', name)
        formField.append('product_code', product_code)
        formField.append('description', description)
        formField.append('manufacturer', manufacturer)
        formField.append('supplier', supplier)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/product/' + id),
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
            data: formField
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            setBranch('')
            setName('')
            setProductCode('')
            setDescription('')
            setManufacturer('')
            setSupplier('')
            getProducts();
        })
    }

    const AddBranchProduct = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (name !== '' &&
        product_code !== '' && description !== '' && manufacturer !== ''
        && supplier !== '') {
            let formField = new FormData()
            formField.append('name', name)
            formField.append('product_code', product_code)
            formField.append('description', description)
            formField.append('manufacturer', manufacturer)
            formField.append('supplier', supplier)

            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-products',
                headers : {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getBranchProducts();
                handleClose();
                setName('')
                setProductCode('')
                setDescription('')
                setManufacturer('')
                setSupplier('')
            })
        }
        setValidated(true);
    }

    const editBranchProduct = async (id) => {
        
        let formField = new FormData()
        formField.append('name', name)
        formField.append('product_code', product_code)
        formField.append('description', description)
        formField.append('manufacturer', manufacturer)
        formField.append('supplier', supplier)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-products/' + id),
            headers: {
                Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
              },
            data: formField
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            handleCloseEdit();
            setName('')
            setProductCode('')
            setDescription('')
            setManufacturer('')
            setSupplier('')
            getBranchProducts();
        })
    }

    const deleteProduct = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/product/' + id, {
            headers: {
            Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
          },
        })
        console.log(response.data)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getProducts();
    }

    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectBranch = (e) => {
        setBranch(e.value)
    }

    const loadBranches = (inputValue, callback) => {
        if (company !== null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const selectSupplier = (e) => {
        setSupplier(e.value)
    }

    const loadSupplier = (inputValue, callback) => {
        searchSupplier(inputValue).then((response) => {
            console.log(response)
            const r = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.email,
                }
            })
            console.log(r)
            callback(r)
        })
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedProducts = products.slice(offset, offset + perPage);
    const paginatedCompanyProducts = companyProducts.slice(offset, offset + perPage);
    const paginatedBranchProducts = branchProducts.slice(offset, offset + perPage);
    const paginatedProductsFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getProducts();
        getCompanyProducts();
        getBranchProducts();
    }, [])

    const ListProducts = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        
        if (user.is_superuser) {

            return value.length > 0 ? paginatedProductsFilter.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedProducts.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            }

        // Get company products of loggedIn user
        else if(user.company !== null && user.is_superuser) {
            return value.length > 0 ? paginatedProductsFilter.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedCompanyProducts.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }

        //  Get branch products of loggeIn staff
        else {
            return value.length > 0 ? paginatedProductsFilter.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedBranchProducts.map((product, prd) => {
                return (
                <tr key={product.id}>
                    <td scope='row'>{prd+1}</td>
                    <td>{product.name}</td>
                    <td>{product.product_code}</td>
                    <td>{formatAmountWithCommas(product.quantity)}</td>
                    <td>{product.manufacturer}</td>
                    <td>{product.branch.name}</td>
                    {/* <td>{product.company.name}</td> */}
                    <td>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowProduct(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/products" onClick={() => { getProductById(product.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/products" onClick={() => deleteProduct(product.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        }

        if (user.is_superuser) {
                return (
                        <div>
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                            <li className="breadcrumb-item active">Products</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="card">
                                        <div className="row">
                                            <div className="card-header col-sm-9">
                                                <h3 className="card-title">Products</h3>
                                            </div>
                                            <div className="card-header col-sm-3 text-right">
                                                <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Product</Button>
                                            </div>
                                        </div>
                                    
                                        <div className="card-body">
                                                <InputGroup className="mb-3">
                                                        <Form.Control
                                                        placeholder="search"
                                                        aria-label="search"
                                                        aria-describedby="basic-addon1"
                                                        value = {value}
                                                        onChange={filterProducts}
                                                        />
                                                </InputGroup>
                                                <table className="table table-bordered">
                                                    <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Product Code</th>
                                                                <th>Quantity</th>
                                                                <th>Manufacturer</th>
                                                                <th>Branch</th>
                                                                {/* <th>Company</th> */}
                                                                <th>Action</th>
                                                            </tr>
                                                    </thead>
                                                    <tbody>
                                                            <ListProducts />
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(products.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                        </div>
                                    
                                    </div>
                                </div>
                            </section>
                                {/* Add new Product */}
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Product</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated}>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Company</Form.Label>
                                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                                {/* <Form.Control size="sm" type="text" placeholder="Choose company" 
                                                        name="company"
                                                        value={company}
                                                        onChange={(e) => setCompany(e.target.value)}
                                                        /> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Company is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Branch</Form.Label>
                                                <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} required/>
                                                {/* <Form.Control size="sm" type="text" placeholder="Choose branch" 
                                                        name="branch"
                                                        value={branch}
                                                        onChange={(e) => setBranch(e.target.value)}
                                                        /> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Branch is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            </div>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Product Name</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                        />
                                                <Form.Control.Feedback type="invalid">
                                                    Product Name is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Product code</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                        name="product_code"
                                                        value={product_code}
                                                        onChange={(e) => setProductCode(e.target.value)}
                                                        required
                                                        />
                                                <Form.Control.Feedback type="invalid">
                                                    Product code is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            </div>
                                            <Form.Group className="mb-2">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter description"
                                                        name="description"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required
                                                        />
                                                <Form.Control.Feedback type="invalid">
                                                    Product description is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Manufacturer</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                        name="manufacturer"
                                                        value={manufacturer}
                                                        onChange={(e) => setManufacturer(e.target.value)}
                                                        required
                                                        />
                                                <Form.Control.Feedback type="invalid">
                                                    Manufacturer is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Supplier</Form.Label>
                                                <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} required />
                                                {/* <Form.Control size="sm" type="text" placeholder="Choose supplier" 
                                                        name="supplier"
                                                        value={supplier}
                                                        onChange={(e) => setSupplier(e.target.value)}
                                                        /> */}
                                                <Form.Control.Feedback type="invalid">
                                                    Supplier is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            </div>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="success" onClick={AddProduct}>
                                        Submit
                                    </Button>
                                    </Modal.Footer>
                                </Modal>

                                {/* Product Detail */}
                                <Modal show={showProduct} onHide={handleCloseProduct}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Product Info</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>ID</th>
                                                            <td>{product.id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Company</th>
                                                            <td>{product.company ? product.company.name : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Branch</th>
                                                            <td>{product.branch ? product.branch.name : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <td>{product.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Product Code</th>
                                                            <td>{product.product_code}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Description</th>
                                                            <td>{product.description}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Manufacturer</th>
                                                            <td>{product.manufacturer}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Quantity</th>
                                                            <td>{product.quantity}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Supplier</th>
                                                            <td>{ product.supplier ? product.supplier.email : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Created</th>
                                                            <td>{formatDateTimeString(product.created_at)}</td>
                                                        </tr>
                                                    </tbody>
                                        </Table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseProduct}>
                                        Close
                                    </Button>
                                    </Modal.Footer>
                                </Modal>


                                {/* Edit Product */}
                                <Modal show={showEdit} onHide={handleCloseEdit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Product</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Company</Form.Label>
                                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                                {/* <Form.Control size="sm" type="text" placeholder="Choose company" 
                                                        name="company"
                                                        value={company}
                                                        onChange={(e) => setCompany(e.target.value)}
                                                        /> */}
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Branch</Form.Label>
                                                <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} />
                                                {/* <Form.Control size="sm" type="text" placeholder="Choose branch" 
                                                        name="branch"
                                                        defaultValue={product.branch}
                                                        onChange={(e) => setBranch(e.target.value)}
                                                        /> */}
                                            </Form.Group>
                                            </div>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Product Name</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                        name="name"
                                                        defaultValue={product.name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        />
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Product code</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                        name="product_code"
                                                        defaultValue={product.product_code}
                                                        onChange={(e) => setProductCode(e.target.value)}
                                                        />
                                            </Form.Group>
                                            </div>
                                            <Form.Group className="mb-2">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter description"
                                                        name="description"
                                                        defaultValue={product.description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        />
                                            </Form.Group>
                                            <div className='row'>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Manufacturer</Form.Label>
                                                <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                        name="manufacturer"
                                                        defaultValue={product.manufacturer}
                                                        onChange={(e) => setManufacturer(e.target.value)}
                                                        />
                                            </Form.Group>
                                            <Form.Group className="mb-2 col-md-6">
                                                <Form.Label>Supplier</Form.Label>
                                                <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} />
                                            </Form.Group>
                                            </div>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="success" onClick={() => {editProduct(product.id); handleCloseEdit();}}>
                                        Update
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                        </div>
                )
        }
        else if (user.company !== null && user.is_superuser) {
            return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Products</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Products</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Product</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                                <Form.Control
                                                placeholder="search"
                                                aria-label="search"
                                                aria-describedby="basic-addon1"
                                                value = {value}
                                                onChange={filterCompanyProducts}
                                                />
                                        </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Product Code</th>
                                                        <th>Quantity</th>
                                                        <th>Manufacturer</th>
                                                        <th>Branch</th>
                                                        {/* <th>Company</th> */}
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                    <ListProducts />
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <ReactPaginate
                                                        // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                        // nextLabel={<CustomPaginationButtons label="Next" />}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(companyProducts.length / perPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </tr>
                                            </tfoot>
                                        </table>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                        {/* Add new Product */}
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Product</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form noValidate validated={validated}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Branch</Form.Label>
                                        <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} required/>
                                        {/* <Form.Control size="sm" type="text" placeholder="Choose branch" 
                                                name="branch"
                                                value={branch}
                                                onChange={(e) => setBranch(e.target.value)}
                                                /> */}
                                        <Form.Control.Feedback type="invalid">
                                            Branch is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product Name is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product code</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                name="product_code"
                                                value={product_code}
                                                onChange={(e) => setProductCode(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product code is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter description"
                                                name="description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product description is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Manufacturer</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                name="manufacturer"
                                                value={manufacturer}
                                                onChange={(e) => setManufacturer(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Manufacturer is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Supplier</Form.Label>
                                        <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} required />
                                        {/* <Form.Control size="sm" type="text" placeholder="Choose supplier" 
                                                name="supplier"
                                                value={supplier}
                                                onChange={(e) => setSupplier(e.target.value)}
                                                /> */}
                                        <Form.Control.Feedback type="invalid">
                                            Supplier is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={AddProduct}>
                                Submit
                            </Button>
                            </Modal.Footer>
                        </Modal>
        
                        {/* Product Detail */}
                        <Modal show={showProduct} onHide={handleCloseProduct}>
                            <Modal.Header closeButton>
                                <Modal.Title>Product Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td>{product.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company</th>
                                                    <td>{product.company ? product.company.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Branch</th>
                                                    <td>{product.branch ? product.branch.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <td>{product.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Code</th>
                                                    <td>{product.product_code}</td>
                                                </tr>
                                                <tr>
                                                    <th>Description</th>
                                                    <td>{product.description}</td>
                                                </tr>
                                                <tr>
                                                    <th>Manufacturer</th>
                                                    <td>{product.manufacturer}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <td>{product.quantity}</td>
                                                </tr>
                                                <tr>
                                                    <th>Supplier</th>
                                                    <td>{product.supplier ? product.supplier.email : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(product.created_at)}</td>
                                                </tr>
                                            </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseProduct}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
        
        
                        {/* Edit Product */}
                        <Modal show={showEdit} onHide={handleCloseEdit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Product</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Company</Form.Label>
                                        <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                                        {/* <Form.Control size="sm" type="text" placeholder="Choose company" 
                                                name="company"
                                                value={company}
                                                onChange={(e) => setCompany(e.target.value)}
                                                /> */}
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Branch</Form.Label>
                                        <AsyncSelect loadOptions={loadBranches} onChange={(e) => selectBranch(e)} />
                                        {/* <Form.Control size="sm" type="text" placeholder="Choose branch" 
                                                name="branch"
                                                defaultValue={product.branch}
                                                onChange={(e) => setBranch(e.target.value)}
                                                /> */}
                                    </Form.Group>
                                    </div>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                name="name"
                                                defaultValue={product.name}
                                                onChange={(e) => setName(e.target.value)}
                                                />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product code</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                name="product_code"
                                                defaultValue={product.product_code}
                                                onChange={(e) => setProductCode(e.target.value)}
                                                />
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter description"
                                                name="description"
                                                defaultValue={product.description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                />
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Manufacturer</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                name="manufacturer"
                                                defaultValue={product.manufacturer}
                                                onChange={(e) => setManufacturer(e.target.value)}
                                                />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Supplier</Form.Label>
                                        <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} />
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={() => {editProduct(product.id); handleCloseEdit();}}>
                                Update
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
          )
        }
        else {
            return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Products</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Products</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Product</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                                <Form.Control
                                                placeholder="search"
                                                aria-label="search"
                                                aria-describedby="basic-addon1"
                                                value = {value}
                                                onChange={filterBranchProducts}
                                                />
                                        </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Product Code</th>
                                                        <th>Quantity</th>
                                                        <th>Manufacturer</th>
                                                        <th>Branch</th>
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                    <ListProducts />
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <ReactPaginate
                                                        // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                        // nextLabel={<CustomPaginationButtons label="Next" />}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(branchProducts.length / perPage)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </tr>
                                            </tfoot>
                                        </table>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                        {/* Add new Product */}
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Product</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form noValidate validated={validated}>
                                    
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product Name is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product code</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                name="product_code"
                                                value={product_code}
                                                onChange={(e) => setProductCode(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product code is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter description"
                                                name="description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Product description is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Manufacturer</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                name="manufacturer"
                                                value={manufacturer}
                                                onChange={(e) => setManufacturer(e.target.value)}
                                                required
                                                />
                                        <Form.Control.Feedback type="invalid">
                                            Manufacturer is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Supplier</Form.Label>
                                        <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} required />
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={AddBranchProduct}>
                                Submit
                            </Button>
                            </Modal.Footer>
                        </Modal>
        
                        {/* Product Detail */}
                        <Modal show={showProduct} onHide={handleCloseProduct}>
                            <Modal.Header closeButton>
                                <Modal.Title>Product Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td>{product.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company</th>
                                                    <td>{product.company ? product.company.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Branch</th>
                                                    <td>{product.branch ? product.branch.name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <td>{product.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Code</th>
                                                    <td>{product.product_code}</td>
                                                </tr>
                                                <tr>
                                                    <th>Description</th>
                                                    <td>{product.description}</td>
                                                </tr>
                                                <tr>
                                                    <th>Manufacturer</th>
                                                    <td>{product.manufacturer}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <td>{product.quantity}</td>
                                                </tr>
                                                <tr>
                                                    <th>Supplier</th>
                                                    <td>{product.supplier ? product.supplier.email : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(product.created_at)}</td>
                                                </tr>
                                            </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseProduct}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
        
        
                        {/* Edit Product */}
                        <Modal show={showEdit} onHide={handleCloseEdit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Product</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product name" 
                                                name="name"
                                                defaultValue={product.name}
                                                onChange={(e) => setName(e.target.value)}
                                                />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Product code</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter product code" 
                                                name="product_code"
                                                defaultValue={product.product_code}
                                                onChange={(e) => setProductCode(e.target.value)}
                                                />
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter description"
                                                name="description"
                                                defaultValue={product.description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                />
                                    </Form.Group>
                                    <div className='row'>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Manufacturer</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Enter manufacturer"
                                                name="manufacturer"
                                                defaultValue={product.manufacturer}
                                                onChange={(e) => setManufacturer(e.target.value)}
                                                />
                                    </Form.Group>
                                    <Form.Group className="mb-2 col-md-6">
                                        <Form.Label>Supplier</Form.Label>
                                        <AsyncSelect loadOptions={loadSupplier} onChange={(e) => selectSupplier(e)} />
                                    </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={() => {editBranchProduct(product.id);}}>
                                Update
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
          )
        }
}

export default Products