import React, { useState, useEffect } from 'react';
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { formatAmountWithCommas, formatDateTimeString } from '../../utils/DateTimeUtils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function Company() {

    const [companies, setCompanies] = useState([]);
    const [name, setName] = useState("")
    const [created_by, setCreatedBy] = useState("1")
    const [location, setLocation ] = useState("")
    const [company, setCompany] = useState("")
    const [validated, setValidated] = useState(false)
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(companies)
    const [tableFilter, setTableFilter] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showCompany, setShowCompany] = useState(false);
    const handleCloseCompany = () => setShowCompany(false);
    const handleShowCompany = () => setShowCompany(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    const getCompanies = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/companies');
        console.log(response.data);
        setCompanies(response.data);
    }

    const getCompanyById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company/' + id)
        console.log(data)
        setCompany(data.data)
    }

    const AddCompany = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        else if (name !== '' && location !== '' && created_by !== '') {
            let formField = new FormData()
            formField.append('name', name)
            formField.append('location', location)
            formField.append('created_by', created_by)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/companies',
                data: formField,
            }).then((response) => {
                if (response.data.status == 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status == 400) {
                    toast.error(response.data.details)
                }
                getCompanies();
                handleClose();
                setName('');
                setLocation('');
            })
          }
          
        setValidated(true);
    }

    const editCompany = async (id) => {
        
        let formField = new FormData()
        formField.append('name', name)
        formField.append('location', location)
        formField.append('created_by', created_by)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/company/' + id),
            data: formField
        }).then((response) => {
            if (response.data.status == 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status == 400) {
                toast.error(response.data.details)
            }
            setName('');
            setLocation('');
            getCompanies();
        })
    }

    const deleteCompany = async (id) => {
       const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/company/' + id)
       console.log(response.data)
       if (response.data.status == 200) {
           toast.success(response.data.details)
       }
       else if (response.data.status == 400) {
           toast.error(response.data.details)
       }
        getCompanies();
    }

    const filterCompanies = (e) => {
        if(e.target.value != "") {
            setValue(e.target.value);
            const filterTable = companies.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...companies])
        }
    }

    // const generateExcelReport = () => {
    //     const excelData = [
    //       {
    //         columns: [
    //           { title: 'Company Name', width: { wpx: 80 } },
    //           { title: 'Location', width: { wpx: 50 } },
    //           { title: 'Created', width: { wpx: 80 } },
    //         ],
    //         data: companies,
    //       },
    //     ];
    
    //     const excelReport = <ExcelFile element={<button>Generate Excel</button>} filename="report">
    //       <ExcelSheet dataSet={excelData} name="Sheet1" />
    //     </ExcelFile>;
    
    //     return excelReport;
    //   };

    const generatePDFReport = () => {
        const doc = new jsPDF();
        doc.autoTable({
          head: [['Number', 'Company Name', 'Location', 'Created']],
          body: companies.map((row, index) => [index+1, row.name, row.location, formatDateTimeString(row.created_at)]),
        });
        doc.save('CompanyReport.pdf');
      };

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedCompanies = companies.slice(offset, offset + perPage);
    const paginatedCompaniesFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getCompanies();
    }, [])

    const ListCompanies = () => {
        return value.length > 0 ? paginatedCompaniesFilter.map((company, cmp) => {

            const formattedDate = formatDateTimeString(company.created_at);

            return (
            <tr key={company.id}>
                <td scope='row'>{cmp+1}</td>
                <td>{company.name}</td>
                <td>{company.location}</td>
                <td>{formattedDate}</td>
                <td>
                    <Link  to="/companies" onClick={() => { getCompanyById(company.id); handleShowCompany(); }} ><i className="fas fa-eye text-secondary" /></Link>
                    <Link  to="/companies" onClick={() => { getCompanyById(company.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                    <Link  to="/companies" onClick={() => deleteCompany(company.id)} ><i className="fa fa-trash text-danger" /></Link>
                </td>
            </tr>
            )
        }
        )
        :
        paginatedCompanies.map((company, cmp) => {

            const formattedDate = formatDateTimeString(company.created_at);

            return (
            <tr key={company.id}>
                <td scope='row'>{cmp+1}</td>
                <td>{company.name}</td>
                <td>{company.location}</td>
                <td>{formattedDate}</td>
                <td>
                    <Link  to="/companies" onClick={() => { getCompanyById(company.id); handleShowCompany(); }} ><i className="fas fa-eye text-secondary" /></Link>
                    <Link  to="/companies" onClick={() => { getCompanyById(company.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                    <Link  to="/companies" onClick={() => deleteCompany(company.id)} ><i className="fa fa-trash text-danger" /></Link>
                </td>
            </tr>
            )
        }
        )
    }

  return (
    <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Companies</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="row">
                            <div className="card-header col-sm-6">
                                <h3 className="card-title">Companies</h3>
                            </div>
                            <div className="card-header col-sm-3">
                            </div>
                            <div className="card-header col-sm-3 text-right">
                                <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Company</Button>
                            </div>
                        </div>
                    
                        <div className="card-body">
                            <InputGroup className="mb-3">
                                <Form.Control
                                placeholder="search"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value = {value}
                                onChange={filterCompanies}
                                />
                            </InputGroup>
                                <Table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ListCompanies />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(companies.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                            <td className='text-right'>

                                                            <div className="btn-group">
                                                                <Button variant="success" className="btn btn-sm" onClick={generatePDFReport}>
                                                                <i class="fas fa-file-pdf"></i>
                                                                </Button>
                                                                <Button variant="success" className="btn btn-sm" onClick={generatePDFReport}>
                                                                <i class="fas fa-file-excel"></i>
                                                                </Button>
                                                            </div>
                                                            </td>
                                                        </tr>
                                </tfoot>
                                </Table>
                        </div>
                    
                    </div>
                </div>
            </section>

            {/* Add New Company */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Group className="mb-2">
                            <Form.Label>Name</Form.Label>
                            <Form.Control size="sm" type="text" 
                                name = "name"
                                value = {name}
                                placeholder="Enter company name"
                                onChange={(e) => setName(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control size="sm" type="text"
                                name = "location"
                                value = {location}
                                placeholder="Enter company location"
                                onChange={(e) => setLocation(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Location is required
                            </Form.Control.Feedback>
                               <Form.Control size="sm" type="hidden"
                                name = "created_by"
                                value = {created_by}
                                onChange={(e) => setCreatedBy(e.target.value)}
                                />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={AddCompany}>
                    Submit
                </Button>
                </Modal.Footer>
            </Modal>

            {/* View Company Info */}
            <Modal show={showCompany} onHide={handleCloseCompany}>
                <Modal.Header closeButton>
                    <Modal.Title>Company Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{company.id}</td>
                                    </tr>
                                    <tr>
                                        <th>company</th>
                                        <td>{company.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Location</th>
                                        <td>{company.location}</td>
                                    </tr>
                                    <tr>
                                        <th>Created</th>
                                        <td>{formatDateTimeString(company.created_at)}</td>
                                    </tr>
                                </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCompany}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Company Info */}
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label>Name</Form.Label>
                            <Form.Control size="sm" type="text"
                                name='name' 
                                placeholder="Enter company name"
                                defaultValue={company.name}
                                onChange={(e) => setName(e.target.value)}
                             />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control size="sm" type="text"
                                name='name' 
                                placeholder="Enter company location"
                                defaultValue={company.location}
                                onChange={(e) => setLocation(e.target.value)}
                                />
                            <Form.Control size="sm" type="hidden"
                                name = "created_by"
                                defaultValue = {company.created_by}
                                onChange={(e) => setCreatedBy(e.target.value)}
                                />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {editCompany(company.id); handleCloseEdit();}}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>
  )
}

export default Company