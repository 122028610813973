import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString } from '../../utils/DateTimeUtils'

const Expensestype = () => {

    const [expenses_type, setExpensesType] = useState([]);
    const [expense_type, setExpenseType]   = useState("");
    const [name, setName] = useState("");
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(expenses_type)
    const [tableFilter, setTableFilter] = useState([])
  
  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [showExpensesType, setShowExpensesType] = useState(false);
    const handleCloseExpensesType = () => setShowExpensesType(false);
    const handleShowExpensesType = () => setShowExpensesType(true);
  
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
  
      // get all expenses -- Admin part
      const getAllExpensesType = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses_type')
        // , {
        //     headers: {
        //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        //     },
        // });
        console.log(response.data);
        setExpensesType(response.data);
    }
  
    const getExpenseTypeById = async (id) => {
      const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses_type/' + id)
      console.log(response.data)
      setExpenseType(response.data.data)
    }
  
  const AddExpenseType = async (id) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {
  
        let formField = new FormData()
        formField.append('name', name)
  
        await axios({
            method: 'post',
            url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses_type',
            headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            data: formField
        }).then((response) => {
            console.log(response.data)
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            getAllExpensesType();
            handleClose();
            setName('')
        })
    // }
    // setValidated(true);
  }

  const updateExpenseType = async (id) => {

      // const form = event.currentTarget;
      // if (form.checkValidity() === false) {
      //   event.preventDefault();
      //   event.stopPropagation();
      // }
      // else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {


        let formField = new FormData()
        formField.append('name', name)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses_type/' + id),
        //  headers: {
        //       Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        //     },
            data: formField
        }).then((response) => {
            if (response.data.status == 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status == 400) {
                toast.error(response.data.details)
            }
            getAllExpensesType();
            handleClose();
            setName('')
        })
  }
  
  const deleteExpensestype = async (id) => {
    const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/expenses_type/' + id)
    console.log(response.data)
    if (response.data.status === 200) {
        toast.success(response.data.details)
    }
    else if (response.data.status === 400) {
        toast.error(response.data.details)
    }
     getAllExpensesType();
    }
  
    const filterExpensesType = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = expenses_type.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...expenses_type])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedExpensesType = expenses_type.slice(offset, offset + perPage);
    // const paginatedCompanyExpensesType = companyExpensesType.slice(offset, offset + perPage);
    // const paginatedBranchExpensesType = branchExpensesType.slice(offset, offset + perPage);
    const paginatedExpensesTypeFilter = tableFilter.slice(offset, offset + perPage);


    useEffect(() => {
        getAllExpensesType();
      }, [])

      const ExpensesTypeList = () => {

        return value.length > 0 ? paginatedExpensesTypeFilter.map((expenses_type, xp) => {

            return (
                <tr key={expenses_type.id}>
                    <td scope='row'>{xp+1}</td>
                    <td>{expenses_type.name}</td>
                    <td>{formatDateTimeString(expenses_type.created_at)}</td>
                    <td>
                        <Link  to="/expenses-type" onClick={() => { getExpenseTypeById(expenses_type.id); handleShowExpensesType(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/expenses-type" onClick={() => { getExpenseTypeById(expenses_type.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/expenses-type" onClick={() => deleteExpensestype(expenses_type.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
            )
        }
        )
        :

        
        paginatedExpensesType.map((expensetype, xp) => (
          
          <tr key={expensetype.id}>
              <td scope='row'>{xp+1}</td>
              <td>{expensetype.name}</td>
              <td>{formatDateTimeString(expensetype.created_at)}</td>
              <td>
                  <Link  to="/expenses-type" onClick={() => { getExpenseTypeById(expensetype.id); handleShowExpensesType(); }} ><i className="fas fa-eye text-secondary" /></Link>
                  <Link  to="/expenses-type" onClick={() => { getExpenseTypeById(expensetype.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                  <Link  to="/expenses-type" onClick={() => deleteExpensestype(expensetype.id)} ><i className="fa fa-trash text-danger" /></Link>
              </td>
          </tr>
        )
          )
      }
      
      return (

        <div>
        <section className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                        <li className="breadcrumb-item active">Expenses Type</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="container-fluid">
                <div className="card">
                    <div className="row">
                        <div className="card-header col-sm-9">
                            <h3 className="card-title">Expenses Type</h3>
                        </div>
                        <div className="card-header col-sm-3 text-right">
                            <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Expenses Type</Button>
                        </div>
                    </div>
                
                    <div className="card-body">
                            <InputGroup className="mb-3">
                                <Form.Control
                                placeholder="search"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value = {value}
                                onChange={filterExpensesType}
                                />
                            </InputGroup>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Expense Name</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ExpensesTypeList />
                                </tbody>
                                <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(expenses_type.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                </tfoot>
                            </table>
                    </div>
                
                </div>
            </div>
        </section>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Expenses Type</Modal.Title>
        </Modal.Header>
                    <Modal.Body>
                      <Form noValidate validated={validated}>
                          <Form.Group className="mb-2">
                              <Form.Label>Expense Name</Form.Label>
                              <Form.Control size="sm" type="text" 
                                  name = "name"
                                  value = {name}
                                  placeholder="Enter Expense Name"
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                  />
                              <Form.Control.Feedback type="invalid">
                                  Expense Name is required
                              </Form.Control.Feedback>
                          </Form.Group>
                                 {/* <Form.Control size="sm" type="hidden"
                                  name = "created_by"
                                  value = {created_by}
                                  onChange={(e) => setCreatedBy(e.target.value)}
                                  />
                          </Form.Group> */}
                      </Form>
                    </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={AddExpenseType}>
            Submit
        </Button>
        </Modal.Footer>
        </Modal>

        {/* Expenses Type Detail */}
        <Modal show={showExpensesType} onHide={handleCloseExpensesType}>
            <Modal.Header closeButton>
                <Modal.Title>Expenses Type Info</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Table className="table table-bordered">
                                        <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td>{expense_type.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Expense Name</th>
                                                    <td>{expense_type.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created</th>
                                                    <td>{formatDateTimeString(expense_type.created_at)}</td>
                                                </tr>
                                        </tbody>
                    </Table>
                </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseExpensesType}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>


        {/* Edit Expenses Type */}
        <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Expenses Type</Modal.Title>
        </Modal.Header>
                  <Modal.Body>
                      <Form noValidate validated={validated}>
                          <Form.Group className="mb-2">
                              <Form.Label>Expense Name</Form.Label>
                              <Form.Control size="sm" type="text" 
                                  name = "name"
                                  defaultValue = {expense_type.name}
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                  />
                              <Form.Control.Feedback type="invalid">
                                  Expense Name is required
                              </Form.Control.Feedback>
                          </Form.Group>
                                 {/* <Form.Control size="sm" type="hidden"
                                  name = "created_by"
                                  value = {created_by}
                                  onChange={(e) => setCreatedBy(e.target.value)}
                                  />
                          </Form.Group> */}
                      </Form>
                  </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={() => {updateExpenseType(expense_type.id); handleCloseEdit();}}>
            Update
        </Button>
        </Modal.Footer>
        </Modal>

        </div>

      )

}

export default Expensestype