import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
            <li className="nav-item">
            <Link className="nav-link" data-widget="pushmenu" to="/" role="button"><i className="fas fa-bars" /></Link>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link">Contact</Link>
            </li>
        </ul>
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
            <Link className="nav-link" data-widget="fullscreen" to="/" role="button">
                <i className="fas fa-expand-arrows-alt" />
            </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" data-widget="control-sidebar" data-slide="true" to="/" role="button">
                <i className="fas fa-th-large" />
            </Link>
            </li>
        </ul>
        </nav>
    </>
  )
}

export default Header