import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Chart } from 'react-google-charts'
import { formatAmountWithCommas } from '../../utils/DateTimeUtils'

function Dashboard() {

  const [dashboard, setDashboard] = useState('')
  const [companydashboard, setCompanyDashboard] = useState('')
  const [branchdashboard, setBranchDashboard] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  console.log(user)


  const dashboardStatistics = async () => {
    const response  = await axios.get("https://master-business-application-772r7.ondigitalocean.app/api/v1/dashboardstats", {
      headers: {
        Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
      },
    })
    console.log(response.data)
    setDashboard(response.data)
  }

  const CompanydashboardStatistics = async () => {
    const response  = await axios.get("https://master-business-application-772r7.ondigitalocean.app/api/v1/companydashboardstats", {
      headers: {
        Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
      },
    })
    console.log(response.data)
    setCompanyDashboard(response.data)
  }

  const BranchdashboardStatistics = async () => {
    const response  = await axios.get("https://master-business-application-772r7.ondigitalocean.app/api/v1/branchdashboardstats", {
    headers: {
      Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
    },
  })
    console.log(response.data)
    setBranchDashboard(response.data)
  }

  useEffect(() =>{
    dashboardStatistics();
    CompanydashboardStatistics();
    BranchdashboardStatistics();
}, [])

if (user.is_superuser) {
    return (
      <div>
            <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                    </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                      <div className="row">
                          <div className='col-lg-2'>
                                <div className="small-box bg-white">
                                  <div className="inner">
                                    <h3>{dashboard.companies}</h3>

                                    <p><strong>Companies</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-info">
                                <div className="inner">
                                  <h3>{dashboard.branches}</h3>

                                  <p><strong>Branches</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                          <div className="card col-lg-4">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          <section className="col-lg-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className='col-lg-2'>
                                <div className="small-box bg-warning">
                                  <div className="inner">
                                    <h3>{formatAmountWithCommas(dashboard.total_expenses)}</h3>

                                    <p><strong>All Expenses (TZS)</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-secondary">
                                <div className="inner">
                                  <h3>{dashboard.staffs}</h3>

                                  <p><strong>Users</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className='col-lg-2'>
                                <div className="small-box bg-success">
                                  <div className="inner">
                                    <h3>{dashboard.products}</h3>

                                    <p><strong>Products</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-danger">
                                <div className="inner">
                                  <h3>{dashboard.sales}</h3>

                                  <p><strong>Sales</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                            <div className="card col-lg-3">
                                  <div className="card-body">
                                    <div className="tab-content">
                                      <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                        
                                        <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                      </div>
                                      <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                        
                                        <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                      </div>
                                    </div>
                                  </div>
                            </div>
                          <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            <div className="card col-lg-4">
                                <div className="card-body">
                                  <div className="tab-content">
                                    <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                      
                                      <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                    </div>
                                    <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                      
                                      <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                    </div>
                                  </div>
                                </div>
                            </div>
                      </div>
                </div>
            </section>
      </div>
    )
}

else if (user.company !== null && user.is_superuser) {
    return (
      <div>
            <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                    </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                      <div className="row">
                          <div className='col-lg-2'>
                                <div className="small-box bg-white">
                                  <div className="inner">
                                    <h3>{dashboard.companies}</h3>

                                    <p><strong>Companies</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-info">
                                <div className="inner">
                                  <h3>{dashboard.branches}</h3>

                                  <p><strong>Branches</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                          <div className="card col-lg-4">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          <section className="col-lg-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className='col-lg-2'>
                                <div className="small-box bg-warning">
                                  <div className="inner">
                                    <h3>{formatAmountWithCommas(companydashboard.total_company_expenses)}</h3>

                                    <p><strong>Company Expenses (TZS)</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-secondary">
                                <div className="inner">
                                  <h3>{companydashboard.staffs}</h3>

                                  <p><strong>Users</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className='col-lg-2'>
                                <div className="small-box bg-success">
                                  <div className="inner">
                                    <h3>{dashboard.products}</h3>

                                    <p><strong>Products</strong></p>
                                  </div>
                                  <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                  </div>
                                </div>
                              <div className="small-box bg-danger">
                                <div className="inner">
                                  <h3>{formatAmountWithCommas(companydashboard.company_sales)}</h3>

                                  <p><strong>Sales (TZS)</strong></p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                              </div>
                          </div>
                            <div className="card col-lg-3">
                                  <div className="card-body">
                                    <div className="tab-content">
                                      <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                        
                                        <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                      </div>
                                      <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                        
                                        <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                      </div>
                                    </div>
                                  </div>
                            </div>
                          <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            <div className="card col-lg-4">
                                <div className="card-body">
                                  <div className="tab-content">
                                    <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                      
                                      <canvas id="revenue-chart-canvas" height={100} style={{height: 100}} />

                                    </div>
                                    <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                      
                                      <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                    </div>
                                  </div>
                                </div>
                            </div>
                      </div>
                </div>
            </section>
      </div>
    )
}

else {
    return (
      <div>
            <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                    </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                      <div className="row">
                        <div className='col-lg-3'>
                            <div className="small-box bg-success">
                            <div className="inner">
                            <h3>{branchdashboard.branch_products}</h3>

                            <p><strong>Products</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                            </div>
                            </div>
                          </div>
                        <div className='col-lg-3'>
                            <div className="small-box bg-info">
                            <div className="inner">
                            <h3>{branchdashboard.branch_transfers}</h3>

                            <p><strong>Transfers</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-bag"></i>
                            </div>
                            </div>
                          </div>
                        <div className='col-lg-3'>
                            <div className="small-box bg-warning">
                            <div className="inner">
                            <h3>{formatAmountWithCommas(branchdashboard.total_company_branches_expenses)}</h3>

                            <p><strong>Expenses (TZS)</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                            </div>
                            </div>
                          </div>
                        <div className='col-lg-3'>
                            <div className="small-box bg-secondary">
                            <div className="inner">
                            <h3>{branchdashboard.branch_staffs}</h3>

                            <p><strong>Staffs</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-bag"></i>
                            </div>
                            </div>
                          </div>
                        <div className='col-lg-3'>
                            <div className="small-box bg-danger">
                            <div className="inner">
                            <h3>{formatAmountWithCommas(branchdashboard.branch_sales)}</h3>

                            <p><strong>Sales (TZS)</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-bag"></i>
                            </div>
                            </div>
                          </div>
                          <div className='col-lg-3'>
                            <div className="small-box bg-white">
                            <div className="inner">
                            <h3>{formatAmountWithCommas(branchdashboard.total_company_branches_purchase)}</h3>

                            <p><strong>Purchases (TZS)</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                            </div>
                            </div>
                          </div>
                          <div className='col-lg-3'>
                            <div className="small-box bg-dark">
                            <div className="inner">
                            <h3>{formatAmountWithCommas(branchdashboard.profit)}</h3>

                            <p><strong>Net Profit (TZS)</strong></p>
                            </div>
                            <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                            </div>
                            </div>
                          </div>
                      </div>
                      <div className="row">
                        <div className='col-lg-4'>
                            <div className="card">
                                  <div className="card-body">
                                    <div className="tab-content">
                                      <div className="chart tab-pane active"  style={{position: 'relative', height: 106}}>
                                        
                                      <Chart chartType="Histogram" data={[["Age", "Weight"], [4, 5.5], [8, 12]]} height={100} style={{height: 100}}  legendToggle />

                                      </div>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        <div className='col-lg-4'>
                            <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active" id="revenue-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <Chart chartType="LineChart" data={[["Age", "Weight"], [4, 5.5], [8, 12]]} height={100} style={{height: 100}}  legendToggle />

                                  </div>
                                  <div className="chart tab-pane" id="sales-chart" style={{position: 'relative', height: 106}}>
                                    
                                    <canvas id="sales-chart-canvas" height={100} style={{height: 100}} />

                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          <div className="col-lg-4">
                          <div className="card">
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="chart tab-pane active"  style={{position: 'relative', height: 106}}>
                                    
                                  <Chart chartType="Histogram" data={[["Age", "Weight"], [4, 5.5], [8, 12]]} height={100} style={{height: 100}}  legendToggle />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                </div>
            </section>
      </div>
    )
}
}

export default Dashboard