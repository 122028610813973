import axios from 'axios'
import { Config } from './Config'
import { setAuthToken } from '../features/userSlice'
import { useDispatch } from 'react-redux'

function performRequest(method, payload, authenticated = true, isLogoutRequest = false) {
  // const auth = store && store.getState().auth;
  // const authHeaders = authenticated && auth && auth.session ? {
  //   'Authorization': 'Bearer ' + auth.session.token
  // } : {};

  return new Promise((resolve, reject) => {
    // const headers = {
    //   'Content-Type': 'application/json',
    //   'X-API-Method': method,
    //   'X-UI-Language': getLanguageCode(i18n.languages),
    //   ...authHeaders
    // };
    // const data = JSON.stringify(payload);

    axios
      .post(Config.apiURL + method, payload)
      .then((response) => {
        if ('x-refreshed-token' in response.headers) {
          useDispatch(setAuthToken(response.headers['x-refreshed-token']))
        }
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
          // store && logOut(store.dispatch);
          return
        }
        if (error.response && error.response.status === 429) {
          // if (snackbarRef) {
          // //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
          // }
        }
        console.log(error)
        reject(error)
      })
  })
}

export function loginRequest(username, password, rememberMe = false, mfaCode = '') {
  const additional = {}
  if (mfaCode !== '') {
    additional.mfaCode = mfaCode
  }
  return performRequest(
    'login/',
    {
      username: username,
      password: password,
    },
    false,
  )
}

function performAuthenticatedGetRequest(method, authenticated = true, isLogoutRequest = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(Config.apiURL + method, {
        headers: {
          Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
          // store && logOut(store.dispatch);
          return
        }
        if (error.response && error.response.status === 429) {
          // if (snackbarRef) {
          //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
          // }
        }
        reject(error)
      })
  })
}

function performAuthenticatedPostRequest(
  method,
  payload,
  authenticated = true,
  isLogoutRequest = false,
) {
  return new Promise((resolve, reject) => {
    axios
      .post(Config.apiURL + method, payload, {
        headers: {
          Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
          // store && logOut(store.dispatch);
          return
        }
        if (error.response && error.response.status === 429) {
          // if (snackbarRef) {
          //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
          // }
        }
        reject(error)
      })
  })
}

function performAuthenticatedPutRequest(
  method,
  payload,
  authenticated = true,
  isLogoutRequest = false,
) {
  return new Promise((resolve, reject) => {
    axios
      .put(Config.apiURL + method, payload, {
        headers: {
          Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
          // store && logOut(store.dispatch);
          return
        }
        if (error.response && error.response.status === 429) {
          // if (snackbarRef) {
          //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
          // }
        }
        reject(error)
      })
  })
}

function performAuthenticatedDeleteRequest(method, authenticated = true, isLogoutRequest = false) {
  return new Promise((resolve, reject) => {
    axios
      .delete(Config.apiURL + method, {
        headers: {
          Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401 && authenticated && !isLogoutRequest) {
          // store && logOut(store.dispatch);
          return
        }
        if (error.response && error.response.status === 429) {
          // if (snackbarRef) {
          //   snackbarRef.enqueueSnackbar(i18n.t('common.rateLimitExceeded'), { variant: 'error' });
          // }
        }
        reject(error)
      })
  })
}


// OWNERS RESOURCES
// export function getOwnersRequest() {
//   return performAuthenticatedGetRequest('api/v1/owners')
// }

// export function createOwnerRequest(first_name, email, phone_no, tin_number) {
//   return performAuthenticatedPostRequest('api/v1/owners', {
//     name: first_name,
//     phone_no: phone_no,
//     tin_number: tin_number,
//     email: email,
//     created_by: JSON.parse(localStorage.getItem('user_id')),
//   })
// }

// export function editOwnerRequest(id, first_name, email, phone_no, tin_number) {
//   return performAuthenticatedPutRequest('api/v1/owner/' + id, {
//     name: first_name,
//     phone_no: phone_no,
//     tin_number: tin_number,
//     email: email,
//     created_by: JSON.parse(localStorage.getItem('user_id')),
//   })
// }

// export function deleteOwnerRequest(id) {
//   return performAuthenticatedDeleteRequest('api/v1/owner/' + id)
// }

// Smart mauzo RESOURCES
export function searchCompany(inputValue) {
  return performAuthenticatedGetRequest('api/v1/companies_list/?search=' + inputValue)
}

export function searchCompanyBranch(c, b) {
  return performAuthenticatedGetRequest('api/v1/company_branch/?c=' + c + '&' + 'b=' + b)
}

export function searchBranch(inputValue) {
  return performAuthenticatedGetRequest('api/v1/branches/?search=' + inputValue)
}

export function searchSupplier(inputValue) {
  return performAuthenticatedGetRequest('api/v1/suppliers/?search=' + inputValue)
}

export function searchProduct(inputValue) {
  return performAuthenticatedGetRequest('api/v1/products/?search=' + inputValue)
}

export function searchBranchProduct(b, p) {
  return performAuthenticatedGetRequest('api/v1/branch_products/?b=' + b + '&' + 'p=' + p)
}

// Filter products of loggedIn user
export function searchBProduct() {
  return performAuthenticatedGetRequest('api/v1/branch-products')
}

// Filter Branches of loggedIn user
export function searchCBranches() {
  return performAuthenticatedGetRequest('api/v1/company-branches')
}

export function searchStatus(inputValue) {
  return performAuthenticatedGetRequest('api/v1/status/?search=' + inputValue)
}

export function searchExpenses(inputValue) {
  return performAuthenticatedGetRequest('api/v1/expenses/?search=' + inputValue)
}

export function searchExpensesType(inputValue) {
  return performAuthenticatedGetRequest('api/v1/expenses_type/?search=' + inputValue)
}