import React from 'react'
import { Link, useNavigate } from 'react-router-dom'


function Sidebar() {

    const navigate = useNavigate()

    const logOut = () => {
        localStorage.clear();
        navigate("/login")
    }
    

    const user = JSON.parse(localStorage.getItem('user'))
    console.log(user.is_superuser)

  if (user.is_superuser) {
    // admin menu
    return (

        <>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/home" className="brand-link">
            <img src={require('./img/BIMG.jpg')} alt="SmartMauzoLogo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <strong className="brand-text font-weight-light">SMART-MAUZO</strong>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
            <div className="user-panel mt-2 pb-2 mb-2 d-flex">
                <div className="info">
                    <strong className="d-block text-white">{user.email}</strong>
                </div>
            </div>
        <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library */}
            <li className="nav-item menu-open">
            <Link to="/home" className="nav-link active">
                <i className="nav-icon fa fa-home" />
                <p>
                Dashboard
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="/companies" className="nav-link">
                <i className="nav-icon fa fa-university" />
                <p>
                Companies
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="/branches" className="nav-link">
                <i className="nav-icon fa fa-building" />
                <p>
                Branches
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="nav-icon fa fa-archive" />
                <p>
                Products
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/products" className="nav-link">
                    <i className="fa fa-shopping-cart nav-icon" />
                    <p>Products</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/stock-deliveries" className="nav-link">
                    <i className="fa fa-cubes nav-icon" />
                    <p>Deliveries</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/stock-transfer" className="nav-link">
                    <i className="fa fa-truck nav-icon" />
                    <p>Transfer</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="fa fa-tags nav-icon" />
                <p>
                Sales
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/sales" className="nav-link">
                    <i className="fa fa-shopping-bag nav-icon" />
                    <p>Sales</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/sales-report" className="nav-link">
                    <i className="fa fa-file nav-icon" />
                    <p>Report</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/sales" className="nav-link">
                    <i className="fa fa-cart-arrow-down nav-icon" />
                    <p>Return</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="fa fa-tags nav-icon" />
                <p>
                Expenses
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/expenses" className="nav-link">
                    <i className="fa fa-shopping-bag nav-icon" />
                    <p>Expenses</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/expenses-type" className="nav-link">
                    <i className="fa fa-file nav-icon" />
                    <p>Expenses Type</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="fa fa-tags nav-icon" />
                <p>
                Settings
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/status" className="nav-link">
                    <i className="fa fa-shopping-bag nav-icon" />
                    <p>Status</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/sales-report" className="nav-link">
                    <i className="fa fa-file nav-icon" />
                    <p>Settings</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <Link to="/staffs" className="nav-link">
                <i className="nav-icon fa fa-id-card" />
                <p>
                Staff
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="/suppliers" className="nav-link">
                <i className="nav-icon fa fa-users" />
                <p>
                Supplier
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="" onClick={() => {logOut()}} className="nav-link">
                <i className="nav-icon fa fa-power-off" />
                <p>
                Logout
                </p>
            </Link>
            </li>
        </ul>
        </nav>
        {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
        </aside>
        </>

        ) 
    }

  else {
    // users menu
    return (

        <>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/home" className="brand-link">
            <img src={require('./img/BIMG.jpg')} alt="SmartMauzoLogo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <strong className="brand-text font-weight-light">SMART-MAUZO</strong>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
            <div className="user-panel mt-2 pb-2 mb-2 d-flex">
                <div className="info">
                    <strong className="d-block text-white">{user.email}</strong>
                </div>
            </div>
        <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library */}
            <li className="nav-item menu-open">
            <Link to="/home" className="nav-link active">
                <i className="nav-icon fa fa-home" />
                <p>
                Dashboard
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="/branches" className="nav-link">
                <i className="nav-icon fa fa-building" />
                <p>
                Branches
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="nav-icon fa fa-archive" />
                <p>
                Products
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/products" className="nav-link">
                    <i className="fa fa-shopping-cart nav-icon" />
                    <p>Products</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/stock-deliveries" className="nav-link">
                    <i className="fa fa-cubes nav-icon" />
                    <p>Deliveries</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/stock-transfer" className="nav-link">
                    <i className="fa fa-truck nav-icon" />
                    <p>Transfer</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="fa fa-tags nav-icon" />
                <p>
                Sales
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/sales" className="nav-link">
                    <i className="fa fa-shopping-bag nav-icon" />
                    <p>Sales</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/sales-report" className="nav-link">
                    <i className="fa fa-file nav-icon" />
                    <p>Report</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/sales" className="nav-link">
                    <i className="fa fa-cart-arrow-down nav-icon" />
                    <p>Return</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <Link to="/staffs" className="nav-link">
                <i className="nav-icon fa fa-id-card" />
                <p>
                Staff
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="fa fa-tags nav-icon" />
                <p>
                Expenses
                <i className="fas fa-angle-left right" />
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                <Link to="/expenses" className="nav-link">
                    <i className="fa fa-shopping-bag nav-icon" />
                    <p>Expenses</p>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/expenses-type" className="nav-link">
                    <i className="fa fa-file nav-icon" />
                    <p>Expenses Type</p>
                </Link>
                </li>
            </ul>
            </li>
            <li className="nav-item">
            <Link to="/suppliers" className="nav-link">
                <i className="nav-icon fa fa-users" />
                <p>
                Supplier
                </p>
            </Link>
            </li>
            <li className="nav-item">
            <Link to="" onClick={() => {logOut()}} className="nav-link">
                <i className="nav-icon fa fa-power-off" />
                <p>
                Logout
                </p>
            </Link>
            </li>
        </ul>
        </nav>
        {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
        </aside>
        </>

        )
    }
}

export default Sidebar