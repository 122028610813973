import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Modal, Form, Table, Button, InputGroup } from 'react-bootstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import { searchCompanyBranch, searchCompany, searchCBranches } from '../../utils/API'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { formatDateTimeString } from '../../utils/DateTimeUtils';

function Staff() {

    const [staffs, setStaffs] = useState([]);
    const [company, setCompany] = useState("")
    const [branch, setBranch] = useState("")
    const [first_name, setFirstName] =useState("")
    const [last_name, setLastName] =useState("")
    const [phone_number, setPhoneNumber] =useState("")
    const [email, setEmail] =useState("")
    const [city, setCity] =useState("")
    const [staff, setStaff] = useState("")
    const [companyStaffs, setCompanyStaffs] = useState([]);
    const [branchStaffs, setBranchStaffs] = useState([]);
    const [value, setValue] = useState("")
    const [dataSource, setDataSource] = useState(staffs)
    const [companyDataSource, setCompanyDataSource] = useState(companyStaffs)
    const [branchDataSource, setBranchDataSource] = useState(branchStaffs)
    const [tableFilter, setTableFilter] = useState([])
    const [validated, setValidated] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showStaff, setShowStaff] = useState(false);
    const handleCloseStaff = () => setShowStaff(false);
    const handleShowStaff = () => setShowStaff(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const {id} = useParams();

    // Get all staffs -- Admin
    const getStaffs = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/staffs', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data.count);
        setStaffs(response.data.data);
    }

    // Get staffs on company of loggedIn user
    const getCompanyStaffs = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/company-staffs', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setCompanyStaffs(response.data.data);
    }

    // Get staffs on branch of loggedIn user
    const getBranchStaffs = async () => {
        const response = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/branch-staffs', {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        });
        console.log(response.data);
        setBranchStaffs(response.data.data);
    }

    const getStaffById = async (id) => {
        const { data } = await axios.get('https://master-business-application-772r7.ondigitalocean.app/api/v1/staff/' + id, {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        })
        console.log(data)
        setStaff(data)
    }

    const AddStaff = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (company !== '' && branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {

            let formField = new FormData()
            formField.append('company', company)
            formField.append('branch', branch)
            formField.append('first_name', first_name)
            formField.append('last_name', last_name)
            formField.append('phone_number', phone_number)
            formField.append('email', email)
            formField.append('city', city)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/RegisterStaff',
                headers: {
                      Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                    },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getStaffs();
                handleClose();
                setCompany('')
                setBranch('')
                setFirstName('')
                setLastName('')
                setPhoneNumber('')
                setEmail('')
                setCity('')
            })
        }
        setValidated(true);
    }

    const editStaff = async (id) => {
        
        let formField = new FormData()
        formField.append('company', company)
        formField.append('branch', branch)
        formField.append('first_name', first_name)
        formField.append('last_name', last_name)
        formField.append('phone_number', phone_number)
        formField.append('email', email)
        formField.append('city', city)

        await axios({
            method: 'put',
            url: ('https://master-business-application-772r7.ondigitalocean.app/api/v1/staff/' + id),
            headers: {
                  Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                },
            data: formField
        }).then((response) => {
            console.log(response)
            if (response.data.status === 200) {
                toast.success(response.data.details)
            }
            else if (response.data.status === 400) {
                toast.error(response.data.details)
            }
            handleCloseEdit();
            getStaffs();
            setCompany('')
            setBranch('')
            setFirstName('')
            setLastName('')
            setPhoneNumber('')
            setEmail('')
            setCity('')
        })
    }

    const AddCompanyStaff = async (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (branch !== '' && first_name !== '' && last_name !== '' && phone_number !== '' && email !== '' && city !== '') {

            let formField = new FormData()
            formField.append('branch', branch)
            formField.append('first_name', first_name)
            formField.append('last_name', last_name)
            formField.append('phone_number', phone_number)
            formField.append('email', email)
            formField.append('city', city)
    
            await axios({
                method: 'post',
                url: 'https://master-business-application-772r7.ondigitalocean.app/api/v1/company-staffs',
                headers : {
                    Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
                  },
                data: formField
            }).then((response) => {
                console.log(response)
                if (response.data.status === 200) {
                    toast.success(response.data.details)
                }
                else if (response.data.status === 400) {
                    toast.error(response.data.details)
                }
                getCompanyStaffs();
                handleClose();
                setBranch('')
                setFirstName('')
                setLastName('')
                setPhoneNumber('')
                setEmail('')
                setCity('')
            })
        }
        setValidated(true);
    }

    const deleteStaff = async (id) => {
        const response = await axios.delete('https://master-business-application-772r7.ondigitalocean.app/api/v1/staff/' + id, {
            headers: {
              Authorization: 'Token ' + JSON.parse(localStorage.getItem('token')),
            },
        })
        console.log(response)
        if (response.data.status === 200) {
            toast.success(response.data.details)
        }
        else if (response.data.status === 400) {
            toast.error(response.data.details)
        }
        getStaffs();
    }

    const selectCompany = (e) => {
        setCompany(e.value)
    }

    const loadCompany = (inputValue, callback) => {
        searchCompany(inputValue).then((response) => {
            console.log(response)
            const re = response.results.map((data) => {
                console.log(data)
                return {
                    value: data.id,
                    label: data.name,
                  }
            })
            console.log(re)
            callback(re)
        })
    }

    const selectBranch = (e) => {
        setBranch(e.value)
    }

    const loadBranch = (inputValue, callback) => {
        if (company !== null) {
          searchCompanyBranch(company, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
        } else {
          callback([])
        }
    }

    const loadCBranch = (inputValue, callback) => {
          searchCBranches(branch, inputValue).then((response) => {
            console.log('Branches')
            console.log(response)
            const re = response.map((data) => {
              console.log(data)
              return {
                value: data.id,
                label: data.name,
              }
            })
            console.log(re)
            callback(re)
          })
    }

    const filterStaffs = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = staffs.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setDataSource([...staffs])
        }
    }

    const filterCompanyStaffs = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = companyStaffs.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setCompanyDataSource([...companyStaffs])
        }
    }

    const filterBranchStaffs = (e) => {
        if(e.target.value !== "") {
            setValue(e.target.value);
            const filterTable = branchStaffs.filter(o=>Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
                setTableFilter([...filterTable])
        }
        else {
            setValue(e.target.value);
            setBranchDataSource([...branchStaffs])
        }
    }

    const perPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const offset = currentPage * perPage;
    const paginatedStaffs = staffs.slice(offset, offset + perPage);
    const paginatedCompanyStaffs = companyStaffs.slice(offset, offset + perPage);
    const paginatedBranchStaffs = branchStaffs.slice(offset, offset + perPage);
    const paginatedStaffsFilter = tableFilter.slice(offset, offset + perPage);

    useEffect(() => {
        getStaffs();
        getCompanyStaffs();
        getBranchStaffs();
    }, [])

    const StaffList = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        
        if (user.is_superuser) {

            return value.length > 0 ? paginatedStaffsFilter.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedStaffs.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else if ((user.company !== null && user.is_superuser)) {

            return value.length > 0 ? paginatedStaffsFilter.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedCompanyStaffs.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link>
                        <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link>
                    </td>
                </tr>
                )
            }
            )
        }
        else {
            return value.length > 0 ? paginatedStaffsFilter.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        {/* <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link> */}
                        {/* <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link> */}
                    </td>
                </tr>
                )
            }
            )
            :
            paginatedBranchStaffs.map((staff, sf) => {
                return (
                <tr key={staff.id}>
                    <td scope='row'>{sf+1}</td>
                    <td>{staff.first_name}</td>
                    <td>{staff.last_name}</td>
                    <td>{staff.phone_number}</td>
                    <td>{staff.email}</td>
                    <td>
                        <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowStaff(); }} ><i className="fas fa-eye text-secondary" /></Link>
                        {/* <Link  to="/staffs" onClick={() => { getStaffById(staff.id); handleShowEdit(); }} ><i className="fas fa-edit text-primary" /></Link> */}
                        {/* <Link  to="/staffs" onClick={() => deleteStaff(staff.id)} ><i className="fa fa-trash text-danger" /></Link> */}
                    </td>
                </tr>
                )
            }
            )
        }
    }

    if (user.is_superuser) {
        return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Staffs</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Staffs</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Staff</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                            placeholder="search"
                                            aria-label="search"
                                            aria-describedby="basic-addon1"
                                            value = {value}
                                            onChange={filterStaffs}
                                            />
                                        </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Phone Number</th>
                                                        <th>Email</th>
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <StaffList />
                                            </tbody>
                                            <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(staffs.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                            </tfoot>
                                        </table>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                    <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Staff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated}>
                            <div className='row'>
                            <Form.Group className='mb-2 col-md-6'>
                                <Form.Label>Company</Form.Label>
                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} required />
                                <Form.Control.Feedback type="invalid">
                                    Company is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Branch</Form.Label>
                                <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} required />
                                <Form.Control.Feedback type="invalid">
                                    Branch is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter first name"
                                    name="first_name"
                                    value={first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    First name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter last name"
                                    name="last_name"
                                    value={last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Last name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Email is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter phone number"
                                        name="phone_number"
                                        value={phone_number}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                        />
                                <Form.Control.Feedback type="invalid">
                                    Phone number is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>City</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter city"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    City is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="success" onClick={AddStaff}>
                        Submit
                    </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Staff Detail */}
                <Modal show={showStaff} onHide={handleCloseStaff}>
                    <Modal.Header closeButton>
                        <Modal.Title>Staff Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>ID</th>
                                            <td>{staff.id}</td>
                                        </tr>
                                        <tr>
                                            <th>Company</th>
                                            <td>{staff.company ? staff.company.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Branch</th>
                                            <td>{staff.branch ? staff.branch.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>{staff.first_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>{staff.last_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{staff.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone Number</th>
                                            <td>{staff.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{staff.city}</td>
                                        </tr>
                                    </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>


                    {/* Edit Staff */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit staff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className='row'>
                            <Form.Group className='mb-2 col-md-6'>
                                <Form.Label>Company</Form.Label>
                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Branch</Form.Label>
                                <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} />
                            </Form.Group>
                            </div>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="first_name"
                                    defaultValue={staff.first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                        />
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="last_name"
                                    defaultValue={staff.last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                    />
                            </Form.Group>
                            </div>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control size="sm" type="email" 
                                    name="email"
                                    defaultValue={staff.email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                            </Form.Group>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control size="sm" type="text" 
                                    name="phone_number"
                                    defaultValue={staff.phone_number}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                            </Form.Group>
                            <Form.Group className='mb-2 col-md-6'>
                                <Form.Label>City</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="city"
                                    defaultValue={staff.city}
                                    onChange={(e) => setCity(e.target.value)}
                                    />
                            </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="success" onClick={() => {editStaff(staff.id); handleCloseEdit();}}>
                        Update
                    </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
        )
    }
    else if (user.company !== null && user.is_superuser) {
        return (
                <div>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active">Staffs</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="row">
                                    <div className="card-header col-sm-9">
                                        <h3 className="card-title">Staffs</h3>
                                    </div>
                                    <div className="card-header col-sm-3 text-right">
                                        <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Staff</Button>
                                    </div>
                                </div>
                            
                                <div className="card-body">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                            placeholder="search"
                                            aria-label="search"
                                            aria-describedby="basic-addon1"
                                            value = {value}
                                            onChange={filterStaffs}
                                            />
                                        </InputGroup>
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Phone Number</th>
                                                        <th>Email</th>
                                                        <th>Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <StaffList />
                                            </tbody>
                                            <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(companyStaffs.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                            </tfoot>
                                        </table>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                    <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Staff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated}>
                            <Form.Group className="mb-2 col-md-12">
                                <Form.Label>Branch</Form.Label>
                                <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} required />
                                <Form.Control.Feedback type="invalid">
                                    Branch is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter first name"
                                    name="first_name"
                                    value={first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    First name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter last name"
                                    name="last_name"
                                    value={last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Last name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Email is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter phone number"
                                        name="phone_number"
                                        value={phone_number}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                        />
                                <Form.Control.Feedback type="invalid">
                                    Phone number is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>City</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter city"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                    />
                                <Form.Control.Feedback type="invalid">
                                    City is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="success" onClick={AddStaff}>
                        Submit
                    </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Staff Detail */}
                <Modal show={showStaff} onHide={handleCloseStaff}>
                    <Modal.Header closeButton>
                        <Modal.Title>Staff Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>ID</th>
                                            <td>{staff.id}</td>
                                        </tr>
                                        <tr>
                                            <th>Company</th>
                                            <td>{staff.company ? staff.company.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Branch</th>
                                            <td>{staff.branch ? staff.branch.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>{staff.first_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>{staff.last_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{staff.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone Number</th>
                                            <td>{staff.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{staff.city}</td>
                                        </tr>
                                    </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>


                    {/* Edit Staff */}
                    <Modal show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit staff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className='row'>
                            <Form.Group className='mb-2 col-md-6'>
                                <Form.Label>Company</Form.Label>
                                <AsyncSelect loadOptions={loadCompany} onChange={(e) => selectCompany(e)} />
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Branch</Form.Label>
                                <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} />
                            </Form.Group>
                            </div>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="first_name"
                                    defaultValue={staff.first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                        />
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="last_name"
                                    defaultValue={staff.last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                    />
                            </Form.Group>
                            </div>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control size="sm" type="email" 
                                    name="email"
                                    defaultValue={staff.email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                            </Form.Group>
                            <div className='row'>
                            <Form.Group className="mb-2 col-md-6">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control size="sm" type="text" 
                                    name="phone_number"
                                    defaultValue={staff.phone_number}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                            </Form.Group>
                            <Form.Group className='mb-2 col-md-6'>
                                <Form.Label>City</Form.Label>
                                <Form.Control size="sm" type="text"
                                    name="city"
                                    defaultValue={staff.city}
                                    onChange={(e) => setCity(e.target.value)}
                                    />
                            </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="success" onClick={() => {editStaff(staff.id); handleCloseEdit();}}>
                        Update
                    </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
        )
    }
    else {
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                <li className="breadcrumb-item active">Staffs</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="row">
                                <div className="card-header col-sm-12">
                                    <h3 className="card-title">Staffs</h3>
                                </div>
                                {/* <div className="card-header col-sm-3 text-right">
                                    <Button variant="success" className="btn btn-sm" onClick={handleShow}>Add New Staff</Button>
                                </div> */}
                            </div>
                        
                            <div className="card-body">
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                        placeholder="search"
                                        aria-label="search"
                                        aria-describedby="basic-addon1"
                                        value = {value}
                                        onChange={filterCompanyStaffs}
                                        />
                                    </InputGroup>
                                    <table className="table table-bordered">
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone Number</th>
                                                    <th>Email</th>
                                                    <th>Action</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            <StaffList />
                                        </tbody>
                                        <tfoot>
                                                        <tr>
                                                            <ReactPaginate
                                                                // previousLabel={<CustomPaginationButtons label="Previous" />}
                                                                // nextLabel={<CustomPaginationButtons label="Next" />}
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(branchStaffs.length / perPage)}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                            />
                                                        </tr>
                                            </tfoot>
                                    </table>
                            </div>
                        
                        </div>
                    </div>
                </section>
                {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Group className="mb-2">
                            <Form.Label>Branch</Form.Label>
                            <AsyncSelect loadOptions={loadCBranch} onChange={(e) => selectBranch(e)} required />
                            <Form.Control.Feedback type="invalid">
                                Branch is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='row'>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter first name"
                                name="first_name"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                First name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter last name"
                                name="last_name"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Last name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        </div>
                        <Form.Group className="mb-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                Email is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='row'>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter phone number"
                                    name="phone_number"
                                    value={phone_number}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                    />
                            <Form.Control.Feedback type="invalid">
                                Phone number is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>City</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter city"
                                name="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                                />
                            <Form.Control.Feedback type="invalid">
                                City is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={AddCompanyStaff}>
                    Submit
                </Button>
                </Modal.Footer>
                </Modal> */}

                {/* Staff Detail */}
            <Modal show={showStaff} onHide={handleCloseStaff}>
                <Modal.Header closeButton>
                    <Modal.Title>Staff Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{staff.id}</td>
                                    </tr>
                                    <tr>
                                        <th>Company</th>
                                        <td>{staff.company ? staff.company.name : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Branch</th>
                                        <td>{staff.branch ? staff.branch.name : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>First Name</th>
                                        <td>{staff.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th>
                                        <td>{staff.last_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{staff.email}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone Number</th>
                                        <td>{staff.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{staff.city}</td>
                                    </tr>
                                    {/* <tr>
                                        <th>Created at</th>
                                        <td>{formatDateTimeString(staff.created_at)}</td>
                                    </tr> */}
                                </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseStaff}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>


                {/* Edit Staff */}
                {/* <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label>Branch</Form.Label>
                            <AsyncSelect loadOptions={loadBranch} onChange={(e) => selectBranch(e)} />
                        </Form.Group>
                        <div className='row'>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control size="sm" type="text"
                                name="first_name"
                                defaultValue={staff.first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                    />
                        </Form.Group>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control size="sm" type="text"
                                name="last_name"
                                defaultValue={staff.last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                />
                        </Form.Group>
                        </div>
                        <Form.Group className="mb-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control size="sm" type="email" 
                                name="email"
                                defaultValue={staff.email}
                                onChange={(e) => setEmail(e.target.value)}
                                />
                        </Form.Group>
                        <div className='row'>
                        <Form.Group className="mb-2 col-md-6">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control size="sm" type="text" 
                                name="phone_number"
                                defaultValue={staff.phone_number}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                        </Form.Group>
                        <Form.Group className='mb-2 col-md-6'>
                            <Form.Label>City</Form.Label>
                            <Form.Control size="sm" type="text"
                                name="city"
                                defaultValue={staff.city}
                                onChange={(e) => setCity(e.target.value)}
                                />
                        </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={() => {editStaff(staff.id);}}>
                    Update
                </Button>
                </Modal.Footer>
                </Modal> */}
            </div>
        )
            }
}

export default Staff