import { createSlice } from '@reduxjs/toolkit'

// export  const userSlice = createSlice({
//   name
// })
export const sidebarSlice = createSlice({
  name: 'sideBarShow',
  initialState: {
    sideBarShow: false,
  },
  reducers: {
    setSidebarState: (state, action) => {
      state.sideBarShow = action.payload
    },
  },
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    authToken: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload
    },
    logout: (state) => {
      state.user = null
      state.authToken = null
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload
    },
  },
})

export const { login, logout, setAuthToken } = userSlice.actions

export const selectUser = (state) => state.user.user
export const selectAuthToken = (state) => state.authToken.authToken
export const sideBarShow = (state) => state.sideBarShow.sideBarShow
